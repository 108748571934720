import React from 'react';
import ProcedureDetails from './lib/index';
import { Provider } from 'react-redux';
import store from '../../Redux/store';

function app(props) {
  return (
    <Provider store={store}>
      <ProcedureDetails
        applicationId={props.applicationId}
        rowInfo={props.rowInfo}
      />
    </Provider>
  );
}

export const ProcedureSection = app;
