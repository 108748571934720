import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  icdCodeSearch,
  icdSearch,
} from '../../../../Redux/CoderApis/main.actions';
import PropTypes from 'prop-types';
import { Grid, Button } from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveIcon from '@material-ui/icons/Remove';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import IcdDialog from '../IcdDialog';
import './style.css';
import { IcdKeywordDialog } from '../../../../icd-search/src';
import { editIcd } from './../../../../Redux/CoderApis/main.actions';
function Diagnosis(props) {
  const [openIcd, setopenIcd] = useState({ status: false, key: '' });
  const [openIcdkeyword, setopenIcdleyword] = useState({
    status: false,
    key: '',
  });
  const [more, setMore] = useState(false);
  const [icdList, setIcdList] = useState([]);
  const [visitDiagnosisId, setVisitDiagnosisId] = useState(0);
  const [dx1, setDx1] = useState({ icdCode: '' });
  const [dx3, setDx3] = useState({ icdCode: '' });
  const [dx2, setDx2] = useState({ icdCode: '' });
  const [dx4, setDx4] = useState({ icdCode: '' });
  const [dx5, setDx5] = useState({ icdCode: '' });
  const [dx6, setDx6] = useState({ icdCode: '' });
  const [dx7, setDx7] = useState({ icdCode: '' });
  const [dx8, setDx8] = useState({ icdCode: '' });
  const [currentIcd, setCurrentIcd] = useState(0);
  const [icdToEdit, setIcdToEdit] = useState(null);
  const currentRow = JSON.parse(sessionStorage.getItem('currentRow'));
  const clinicId = currentRow.clinicId;

  useEffect(() => {
    let count = 0;
    let icds = [dx1, dx2, dx3, dx4, dx5, dx6, dx7, dx8];
    icds.forEach((icd) => {
      if (icd.icdCode) {
        count = count + 1;
      }
    });
    setCurrentIcd(count);
    props.icdInformation({
      icd1Code: dx1 && dx1.icdCode ? dx1.icdCode : '',
      icd1Description: dx1 && dx1.icdDescLong ? dx1.icdDescLong : '',
      icd1Id: dx1 && dx1.icdId ? dx1.icdId : 0,

      icd2Code: dx2 && dx2.icdCode ? dx2.icdCode : '',
      icd2Description: dx2 && dx2.icdDescLong ? dx2.icdDescLong : '',
      icd2Id: dx2 && dx2.icdId ? dx2.icdId : 0,

      icd3Code: dx3 && dx3.icdCode ? dx3.icdCode : '',
      icd3Description: dx3 && dx3.icdDescLong ? dx3.icdDescLong : '',
      icd3Id: dx3 && dx3.icdId ? dx3.icdId : 0,

      icd4Code: dx4 && dx4.icdCode ? dx4.icdCode : '',
      icd4Description: dx4 && dx4.icdDescLong ? dx4.icdDescLong : '',
      icd4Id: dx4 && dx4.icdId ? dx4.icdId : 0,

      icd5Code: dx5 && dx5.icdCode ? dx5.icdCode : '',
      icd5Description: dx5 && dx5.icdDescLong ? dx5.icdDescLong : '',
      icd5Id: dx5 && dx5.icdId ? dx5.icdId : 0,

      icd6Code: dx6 && dx6.icdCode ? dx6.icdCode : '',
      icd6Description: dx6 && dx6.icdDescLong ? dx6.icdDescLong : '',
      icd6Id: dx6 && dx6.icdId ? dx6.icdId : 0,

      icd7Code: dx7 && dx7.icdCode ? dx7.icdCode : '',
      icd7Description: dx7 && dx7.icdDescLong ? dx7.icdDescLong : '',
      icd7Id: dx7 && dx7.icdId ? dx7.icdId : 0,

      icd8Code: dx8 && dx8.icdCode ? dx8.icdCode : '',
      icd8Description: dx8 && dx8.icdDescLong ? dx8.icdDescLong : '',
      icd8Id: dx8 && dx8.icdId ? dx8.icdId : 0,

      visitDiagnosisId: visitDiagnosisId,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dx1, dx2, dx3, dx4, dx5, dx6, dx7, dx8]);

  useEffect(() => {
    if (props.coderData.icdCodeSearchResponse) {
      let Response = props.coderData.icdCodeSearchResponse.response;
      if (Response.responseCode === 0) {
        let ilist = Response.data.map((x) => {
          return {
            title: x.icdCode + ' ' + x.icdDescLong,
            ...x,
          };
        });
        setIcdList(ilist);
      }
      // else {
      // }
    }
  }, [props.coderData.icdCodeSearchResponse]);

  const icdSearchFn = (e) => {
    if (e.target.value.length > 2) {
      props.icdCodeSearch({
        icdCode: e.target.value,
        clinicId: clinicId,
        applicationId: props.applicationId,
      });
    }
  };

  useEffect(() => {
    if (props.coderData.icdResponse) {
      let Response = props.coderData.icdResponse.response;
      if (Response.responseCode === 0) {
        setopenIcd({ status: false, key: '' });
        let data = Response.data;
        if (openIcd.key === 'DX1') {
          setDx1({
            icdCode: data.icdCode,
            title: data.icdCode,
            icdId: data.icdId,
          });
        } else if (openIcd.key === 'DX2') {
          setDx2({
            icdCode: data.icdCode,
            title: data.icdCode,
            icdId: data.icdId,
          });
        } else if (openIcd.key === 'DX3') {
          setDx3({
            icdCode: data.icdCode,
            title: data.icdCode,
            icdId: data.icdId,
          });
        } else if (openIcd.key === 'DX4') {
          setDx4({
            icdCode: data.icdCode,
            title: data.icdCode,
            icdId: data.icdId,
          });
        } else if (openIcd.key === 'DX5') {
          setDx5({
            icdCode: data.icdCode,
            title: data.icdCode,
            icdId: data.icdId,
          });
        } else if (openIcd.key === 'DX6') {
          setDx6({
            icdCode: data.icdCode,
            title: data.icdCode,
            icdId: data.icdId,
          });
        } else if (openIcd.key === 'DX7') {
          setDx7({
            icdCode: data.icdCode,
            title: data.icdCode,
            icdId: data.icdId,
          });
        } else if (openIcd.key === 'DX8') {
          setDx8({
            icdCode: data.icdCode,
            title: data.icdCode,
            icdId: data.icdId,
          });
        }
      }
      // else {
      // }
    }
  }, [props.coderData.icdResponse]);

  function isEmpty(obj) {
    for (const prop in obj) {
      if (Object.hasOwn(obj, prop)) {
        return false;
      }
    }

    return true;
  }

  const openIcdDialog = (key, icd) => {
    setIcdToEdit(icd);
    setopenIcd({ status: true, key: key });
  };

  useEffect(() => {
    if (props.coderData.procedureInfo) {
      let response = props.coderData.procedureInfo.response;
      if (response.responseCode === 0) {
        let data = response.data;
        let info = data.visitDiagnosisDto;
        if (!isEmpty(info)) {
          setVisitDiagnosisId(info.visitDiagnosisId);
          setDx1({
            title: info.dx1.icdCode ? info.dx1.icdCode : '',
            icdCode: info.dx1.icdCode,
            icdId: info.dx1.icdId,
            icdDescLong: info.dx1.icdDescLong,
          });
          setDx2({
            title: info.dx2.icdCode ? info.dx2.icdCode : '',
            icdCode: info.dx2.icdCode,
            icdId: info.dx2.icdId,
            icdDescLong: info.dx2.icdDescLong,
          });
          setDx3({
            title: info.dx3.icdCode ? info.dx3.icdCode : '',
            icdCode: info.dx3.icdCode,
            icdId: info.dx3.icdId,
            icdDescLong: info.dx3.icdDescLong,
          });
          setDx4({
            title: info.dx4.icdCode ? info.dx4.icdCode : '',
            icdCode: info.dx4.icdCode,
            icdId: info.dx4.icdId,
            icdDescLong: info.dx4.icdDescLong,
          });
          setDx5({
            title: info.dx5.icdCode ? info.dx5.icdCode : '',
            icdCode: info.dx5.icdCode,
            icdId: info.dx5.icdId,
            icdDescLong: info.dx5.icdDescLong,
          });
          setDx6({
            title: info.dx6.icdCode ? info.dx6.icdCode : '',
            icdCode: info.dx6.icdCode,
            icdId: info.dx6.icdId,
            icdDescLong: info.dx6.icdDescLong,
          });
          setDx7({
            title: info.dx7.icdCode ? info.dx7.icdCode : '',
            icdCode: info.dx7.icdCode,
            icdId: info.dx7.icdId,
            icdDescLong: info.dx7.icdDescLong,
          });
          setDx8({
            title: info.dx8.icdCode ? info.dx8.icdCode : '',
            icdCode: info.dx8.icdCode,
            icdId: info.dx8.icdId,
            icdDescLong: info.dx8.icdDescLong,
          });
          if (
            info.dx8.icdCode ||
            info.dx7.icdCode ||
            info.dx6.icdCode ||
            info.dx5.icdCode
          ) {
            setMore(true);
          }
        } else {
          setDx1({
            title: '',
            icdCode: '',
            icdId: 0,
            icdDescLong: '',
          });
          setDx2({
            title: '',
            icdCode: '',
            icdId: 0,
            icdDescLong: '',
          });
          setDx3({
            title: '',
            icdCode: '',
            icdId: 0,
            icdDescLong: '',
          });
          setDx4({
            title: '',
            icdCode: '',
            icdId: 0,
            icdDescLong: '',
          });
          setDx5({
            title: '',
            icdCode: '',
            icdId: 0,
            icdDescLong: '',
          });
          setDx6({
            title: '',
            icdCode: '',
            icdId: 0,
            icdDescLong: '',
          });
          setDx7({
            title: '',
            icdCode: '',
            icdId: 0,
            icdDescLong: '',
          });
          setDx8({
            title: '',
            icdCode: '',
            icdId: 0,
            icdDescLong: '',
          });
        }
      }
    }
  }, [props.coderData.procedureInfo]);

  const handleKeywordValues = (data) => {
    if (openIcdkeyword.key === 'DX1') {
      setDx1({
        icdCode: data?.icdCode,
        title: data?.icdCode,
        icdId: data?.icdId,
      });
    } else if (openIcdkeyword.key === 'DX2') {
      setDx2({
        icdCode: data?.icdCode,
        title: data?.icdCode,
        icdId: data?.icdId,
      });
    } else if (openIcdkeyword.key === 'DX3') {
      setDx3({
        icdCode: data?.icdCode,
        title: data?.icdCode,
        icdId: data?.icdId,
      });
    } else if (openIcdkeyword.key === 'DX4') {
      setDx4({
        icdCode: data?.icdCode,
        title: data?.icdCode,
        icdId: data?.icdId,
      });
    } else if (openIcdkeyword.key === 'DX5') {
      setDx5({
        icdCode: data?.icdCode,
        title: data?.icdCode,
        icdId: data?.icdId,
      });
    } else if (openIcdkeyword.key === 'DX6') {
      setDx6({
        icdCode: data?.icdCode,
        title: data?.icdCode,
        icdId: data?.icdId,
      });
    } else if (openIcdkeyword.key === 'DX7') {
      setDx7({
        icdCode: data?.icdCode,
        title: data?.icdCode,
        icdId: data?.icdId,
      });
    } else if (openIcdkeyword.key === 'DX8') {
      setDx8({
        icdCode: data?.icdCode,
        title: data?.icdCode,
        icdId: data?.icdId,
      });
    }
    const icdArray = ['DX1', 'DX2', 'DX3', 'DX4', 'DX5', 'DX6', 'DX7', 'DX8'];
    const index = icdArray.indexOf(openIcdkeyword.key);
    if (index < icdArray.length - 1) {
      setopenIcdleyword({ status: true, key: icdArray[index + 1] });
    }
  };

  const handleTempSelectedCodes = (data) => {
    if (data) {
      let count = 0;
      data.forEach((icd) => {
        if (icd.icdCode) count++;
      });
      if (count > 4) setMore(true);
      setDx1({
        icdCode: data[0]?.icdCode,
        icdDescLong: data[0]?.icdDescLong,
        title: data[0]?.icdCode,
        icdId: data[0]?.icdId,
      });
      setDx2({
        icdCode: data[1]?.icdCode,
        title: data[1]?.icdCode,
        icdDescLong: data[1]?.icdDescLong,
        icdId: data[1]?.icdId,
      });
      setDx3({
        icdCode: data[2]?.icdCode,
        title: data[2]?.icdCode,
        icdDescLong: data[2]?.icdDescLong,

        icdId: data[2]?.icdId,
      });
      setDx4({
        icdCode: data[3]?.icdCode,
        title: data[3]?.icdCode,
        icdDescLong: data[3]?.icdDescLong,
        icdId: data[3]?.icdId,
      });
      setDx5({
        icdCode: data[4]?.icdCode,
        title: data[4]?.icdCode,
        icdDescLong: data[4]?.icdDescLong,
        icdId: data[4]?.icdId,
      });
      setDx6({
        icdCode: data[5]?.icdCode,
        icdDescLong: data[5]?.icdDescLong,
        title: data[5]?.icdCode,
        icdId: data[5]?.icdId,
      });
      setDx7({
        icdCode: data[6]?.icdCode,
        title: data[6]?.icdCode,
        icdDescLong: data[6]?.icdDescLong,
        icdId: data[6]?.icdId,
      });
      setDx8({
        icdCode: data[7]?.icdCode,
        title: data[7]?.icdCode,
        icdDescLong: data[7]?.icdDescLong,
        icdId: data[7]?.icdId,
      });
    }
  };

  const handleIcd = (param, data) => {
    setIcdList([]);
    if (param === 'dx1') {
      setDx1({
        title: data?.icdCode,
        icdId: data?.icdId,
        icdCode: data?.icdCode,
        icdDescShort: data?.icdDescShort,
        icdDescLong: data?.icdDescLong,
        editable: data?.editable,
        rangeId: data?.rangeId,
      });
    }
    if (param === 'dx2') {
      setDx2({
        title: data?.icdCode,
        icdId: data?.icdId,
        icdCode: data?.icdCode,
        icdDescShort: data?.icdDescShort,
        icdDescLong: data?.icdDescLong,
        editable: data?.editable,
        rangeId: data?.rangeId,
      });
    }
    if (param === 'dx3') {
      setDx3({
        title: data?.icdCode,
        icdId: data?.icdId,
        icdCode: data?.icdCode,
        icdDescShort: data?.icdDescShort,
        icdDescLong: data?.icdDescLong,
        editable: data?.editable,
        rangeId: data?.rangeId,
      });
    }
    if (param === 'dx4') {
      setDx4({
        title: data?.icdCode,
        icdId: data?.icdId,
        icdCode: data?.icdCode,
        icdDescShort: data?.icdDescShort,
        icdDescLong: data?.icdDescLong,
        editable: data?.editable,
        rangeId: data?.rangeId,
      });
    }
    if (param === 'dx5') {
      setDx5({
        title: data?.icdCode,
        icdId: data?.icdId,
        icdCode: data?.icdCode,
        icdDescShort: data?.icdDescShort,
        icdDescLong: data?.icdDescLong,
        editable: data?.editable,
        rangeId: data?.rangeId,
      });
    }
    if (param === 'dx6') {
      setDx6({
        title: data?.icdCode,
        icdId: data?.icdId,
        icdCode: data?.icdCode,
        icdDescShort: data?.icdDescShort,
        icdDescLong: data?.icdDescLong,
        editable: data?.editable,
        rangeId: data?.rangeId,
      });
    }
    if (param === 'dx7') {
      setDx7({
        title: data?.icdCode,
        icdId: data?.icdId,
        icdCode: data?.icdCode,
        icdDescShort: data?.icdDescShort,
        icdDescLong: data?.icdDescLong,
        editable: data?.editable,
        rangeId: data?.rangeId,
      });
    }
    if (param === 'dx8') {
      setDx8({
        title: data?.icdCode,
        icdId: data?.icdId,
        icdCode: data?.icdCode,
        icdDescShort: data?.icdDescShort,
        icdDescLong: data?.icdDescLong,
        editable: data?.editable,
        rangeId: data?.rangeId,
      });
    }
  };

  const checkDuplicateIcd = (option) => {
    const icdArray = [
      dx1?.icdCode,
      dx2?.icdCode,
      dx3?.icdCode,
      dx4?.icdCode,
      dx5?.icdCode,
      dx6?.icdCode,
      dx7?.icdCode,
      dx8?.icdCode,
    ];
    return icdArray.includes(option.icdCode);
  };

  const openIcdPopup = (x) => {
    setopenIcdleyword(x);
  };

  return (
    <div className="diagnosis_main_div">
      {/* <p className="diagnosis_heading">Diagnosis</p> */}
      <div>
        <Grid container spacing={1}>
          <Grid item xs={9}>
            <Grid container spacing={2} className="diagnosis_grid">
              <Grid item xs={3}>
                <div className="dx_field_set">
                  <Autocomplete
                    fullWidth
                    options={icdList}
                    onChange={(e, x) => handleIcd('dx1', x)}
                    value={dx1}
                    getOptionLabel={(option) => option.title}
                    getOptionDisabled={(option) => checkDuplicateIcd(option)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="DX1"
                        onChange={icdSearchFn}
                      />
                    )}
                  />

                  <AddCircleOutlineIcon
                    color="primary"
                    fontSize="small"
                    className={'dx_addicon'}
                    // onClick={() => setopenIcd({ status: true, key: 'DX1' })}
                    onClick={() => openIcdDialog('DX1', dx1)}
                  />
                  <CloseIcon
                    color="primary"
                    fontSize="small"
                    className={'dx_closeIcon'}
                    // onClick={() => setDx1({ icdCode: '' })}
                    onClick={() =>
                      setDx1({
                        title: '',
                        icdCode: '',
                        icdId: 0,
                        icdDescLong: '',
                      })
                    }
                  />
                </div>
              </Grid>
              <Grid item xs={3}>
                <div className="dx_field_set">
                  <Autocomplete
                    fullWidth
                    options={icdList}
                    onChange={(e, x) => handleIcd('dx2', x)}
                    value={dx2}
                    getOptionDisabled={(option) => checkDuplicateIcd(option)}
                    getOptionLabel={(option) => option.title}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="DX2"
                        onChange={icdSearchFn}
                      />
                    )}
                  />
                  <AddCircleOutlineIcon
                    color="primary"
                    fontSize="small"
                    className={'dx_addicon'}
                    // onClick={() => setopenIcd({ status: true, key: 'DX2' })}
                    onClick={() => openIcdDialog('DX2', dx2)}
                  />
                  <CloseIcon
                    color="primary"
                    fontSize="small"
                    className={'dx_closeIcon'}
                    // onClick={() => setDx2({ icdCode: '' })}
                    onClick={() =>
                      setDx2({
                        title: '',
                        icdCode: '',
                        icdId: 0,
                        icdDescLong: '',
                      })
                    }
                  />
                </div>
              </Grid>
              <Grid item xs={3}>
                <div className="dx_field_set">
                  <Autocomplete
                    fullWidth
                    options={icdList}
                    onChange={(e, x) => handleIcd('dx3', x)}
                    value={dx3}
                    getOptionDisabled={(option) => checkDuplicateIcd(option)}
                    getOptionLabel={(option) => option.title}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="DX3"
                        onChange={icdSearchFn}
                      />
                    )}
                  />
                  <AddCircleOutlineIcon
                    color="primary"
                    fontSize="small"
                    className={'dx_addicon'}
                    // onClick={() => setopenIcd({ status: true, key: 'DX3' })}
                    onClick={() => openIcdDialog('DX3', dx3)}
                  />
                  <CloseIcon
                    color="primary"
                    fontSize="small"
                    className={'dx_closeIcon'}
                    onClick={() =>
                      setDx3({
                        title: '',
                        icdCode: '',
                        icdId: 0,
                        icdDescLong: '',
                      })
                    }
                  />
                </div>
              </Grid>
              <Grid item xs={3}>
                <div className="dx_field_set">
                  <Autocomplete
                    fullWidth
                    options={icdList}
                    onChange={(e, x) => handleIcd('dx4', x)}
                    value={dx4}
                    getOptionDisabled={(option) => checkDuplicateIcd(option)}
                    getOptionLabel={(option) => option.title}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="DX4"
                        onChange={icdSearchFn}
                      />
                    )}
                  />
                  <AddCircleOutlineIcon
                    color="primary"
                    fontSize="small"
                    className={'dx_addicon'}
                    onClick={() => openIcdDialog('DX3', dx4)}
                    // onClick={() => setopenIcd({ status: true, key: 'DX4' })}
                  />
                  <CloseIcon
                    color="primary"
                    fontSize="small"
                    className={'dx_closeIcon'}
                    // onClick={() => setDx4({ icdCode: '' })}
                    onClick={() =>
                      setDx4({
                        title: '',
                        icdCode: '',
                        icdId: 0,
                        icdDescLong: '',
                      })
                    }
                  />
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={3}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row-reverse',
                paddingBottom: '10px',
                paddingTop: '12px',
                gap: '0.3rem',
                // border: '1px solid red',
              }}
            >
              <Button
                color="primary"
                variant="outlined"
                startIcon={
                  more ? (
                    <RemoveIcon color={'primary'} />
                  ) : (
                    <AddCircleOutlineIcon color={'primary'} />
                  )
                }
                onClick={() => setMore(!more)}
              >
                {more ? 'Less' : 'Add'}
              </Button>
              <Button
                color="primary"
                onClick={() => openIcdPopup({ status: true, key: 'DX1' })}
                size="small"
                variant="outlined"
                startIcon={<SearchIcon color="primary" />}
              >
                Search
              </Button>
            </div>
          </Grid>
        </Grid>
      </div>
      {/* Next Block*/}
      <div>
        <div hidden={!more}>
          <Grid container className="diagnosis_grid" spacing={2}>
            <Grid item xs={3}>
              <div className="dx_field_set">
                <Autocomplete
                  fullWidth
                  options={icdList}
                  onChange={(e, x) => handleIcd('dx5', x)}
                  value={dx5}
                  getOptionDisabled={(option) => checkDuplicateIcd(option)}
                  getOptionLabel={(option) => option.title}
                  renderInput={(params) => (
                    <TextField {...params} label="DX5" onChange={icdSearchFn} />
                  )}
                />
                <AddCircleOutlineIcon
                  color="primary"
                  fontSize="small"
                  className={'dx_addicon'}
                  // onClick={() => setopenIcd({ status: true, key: 'DX5' })}
                  onClick={() => openIcdDialog('DX5', dx5)}
                />
                {/* <SearchIcon
                  onClick={() => openIcdPopup({ status: true, key: 'DX5' })}
                  color="primary"
                  fontSize="small"
                  className={'dx_searchicon'}
                /> */}
                <CloseIcon
                  color="primary"
                  fontSize="small"
                  className={'dx_closeIcon'}
                  onClick={() =>
                    setDx5({
                      title: '',
                      icdCode: '',
                      icdId: 0,
                      icdDescLong: '',
                    })
                  }
                />
              </div>
            </Grid>
            <Grid item xs={3}>
              <div className="dx_field_set">
                <Autocomplete
                  fullWidth
                  options={icdList}
                  onChange={(e, x) => handleIcd('dx6', x)}
                  value={dx6}
                  getOptionDisabled={(option) => checkDuplicateIcd(option)}
                  getOptionLabel={(option) => option.title}
                  renderInput={(params) => (
                    <TextField {...params} label="DX6" onChange={icdSearchFn} />
                  )}
                />
                <AddCircleOutlineIcon
                  color="primary"
                  fontSize="small"
                  className={'dx_addicon'}
                  // onClick={() => setopenIcd({ status: true, key: 'DX6' })}
                  onClick={() => openIcdDialog('DX6', dx6)}
                />
                <CloseIcon
                  color="primary"
                  fontSize="small"
                  className={'dx_closeIcon'}
                  onClick={() =>
                    setDx6({
                      title: '',
                      icdCode: '',
                      icdId: 0,
                      icdDescLong: '',
                    })
                  }
                />
              </div>
            </Grid>
            <Grid item xs={3}>
              <div className="dx_field_set">
                <Autocomplete
                  fullWidth
                  options={icdList}
                  onChange={(e, x) => handleIcd('dx7', x)}
                  value={dx7}
                  getOptionDisabled={(option) => checkDuplicateIcd(option)}
                  getOptionLabel={(option) => option.title}
                  renderInput={(params) => (
                    <TextField {...params} label="DX7" onChange={icdSearchFn} />
                  )}
                />
                <AddCircleOutlineIcon
                  color="primary"
                  fontSize="small"
                  className={'dx_addicon'}
                  // onClick={() => setopenIcd({ status: true, key: 'DX7' })}
                  onClick={() => openIcdDialog('DX7', dx7)}
                />
                {/* <SearchIcon
                  onClick={() => openIcdPopup({ status: true, key: 'DX7' })}
                  color="primary"
                  fontSize="small"
                  className={'dx_searchicon'}
                /> */}
                <CloseIcon
                  color="primary"
                  fontSize="small"
                  className={'dx_closeIcon'}
                  onClick={() =>
                    setDx7({
                      title: '',
                      icdCode: '',
                      icdId: 0,
                      icdDescLong: '',
                    })
                  }
                />
              </div>
            </Grid>
            <Grid item xs={3}>
              <div className="dx_field_set">
                <Autocomplete
                  fullWidth
                  options={icdList}
                  onChange={(e, x) => handleIcd('dx8', x)}
                  value={dx8}
                  getOptionDisabled={(option) => checkDuplicateIcd(option)}
                  getOptionLabel={(option) => option.title}
                  renderInput={(params) => (
                    <TextField {...params} label="DX8" onChange={icdSearchFn} />
                  )}
                />
                <AddCircleOutlineIcon
                  color="primary"
                  fontSize="small"
                  className={'dx_addicon'}
                  // onClick={() => setopenIcd({ status: true, key: 'DX8' })}
                  onClick={() => openIcdDialog('DX8', dx8)}
                />
                {/* <SearchIcon
                  onClick={() => openIcdPopup({ status: true, key: 'DX8' })}
                  color="primary"
                  fontSize="small"
                  className={'dx_searchicon'}
                /> */}
                <CloseIcon
                  color="primary"
                  fontSize="small"
                  className={'dx_closeIcon'}
                  onClick={() =>
                    setDx8({
                      title: '',
                      icdCode: '',
                      icdId: 0,
                      icdDescLong: '',
                    })
                  }
                />
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <IcdDialog
        open={openIcd.status}
        applicationId={props.applicationId}
        icd={icdToEdit}
        rowInfo={props.rowInfo}
        handleClose={() => setopenIcd({ status: false })}
      />
      <IcdKeywordDialog
        open={openIcdkeyword.status}
        current={currentIcd}
        rowInfo={props.rowInfo}
        applicationId={props.applicationId}
        handleNextIcd={setopenIcdleyword}
        currentKey={openIcd.key}
        icdArray={[dx1, dx2, dx3, dx4, dx5, dx6, dx7, dx8]}
        handleClose={() => setopenIcdleyword(false)}
        keywordList={handleKeywordValues}
        handleTempSelectedCodes={handleTempSelectedCodes}
      />
    </div>
  );
}

const mapStateToProps = (state) => ({
  coderData: state.coder,
});

const mapDispatchToProps = (dispatch) => ({
  icdSearch: (values) => dispatch(icdSearch(values)),
  editIcd: (values) => dispatch(editIcd(values)),
  icdCodeSearch: (values) => dispatch(icdCodeSearch(values)),
});
Diagnosis.propTypes = {
  icdSearch: PropTypes.func,
  icdCodeSearch: PropTypes.func,
  editIcd: PropTypes.func,
};
export default connect(mapStateToProps, mapDispatchToProps)(Diagnosis);
