import React, { useEffect, useState } from 'react';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { alpha, styled } from '@material-ui/core/styles';
import DateFnsUtils from '@date-io/date-fns';
import FormControl from '@material-ui/core/FormControl';
/** @file Manages reusable Date Picker
 * @author Akhil
 */

/**
 * Mask for date picker
 * @param {props} value from the parent compontent
 * @return {JSXElement}
 */

/**
 * Reusable Date Picker
 * @param {props} value from the parent compontent
 * @return {JSXElement}
 */

const DateField = styled(KeyboardDatePicker)({
  '& .PickerWithState': {
    '&: Mui-focused': {
      border: 'none',
    },
  },

  'label + &': {
    marginTop: '1.3rem',
  },

  '& .Mui-focused': {
    border: 'none',
    boxShadow: `${alpha('#F2F2F2', 0.2)} 0.3px -1.5px 0px 0.1rem`,
    borderColor: '#F2F2F2',
    borderBottom: '0',
    outline: 'none',
    transition:
      'border-color 300ms cubic-bezier(0.4, 0, 0.2, 0.1) 0ms,box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 0.1) 0ms',
  },
  '& fieldset': {
    top: '-5px',
  },
  '#date-of-birth': {
    borderBottom: '1px solid red !important',
  },

  '& .MuiSvgIcon-root': {
    width: '0.8em !important',
  },

  '& .MuiIconButton-root': {
    padding: '2px 0 !important',
  },
});

function DobPicker(props) {
  const [value, setValues] = useState(props.value ? props.value : null);
  // const [maxDate, setMaxDate] = useState(props.maxDate ? props.maxDate : null);
  /**
   * rendering the props value
   */
  useEffect(() => {
    if (props.value === null || props.value === '') {
      setValues(null);
    } else {
      setValues(props.value);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.value]);
  // useEffect(() => {
  //   if (props.value === null || props.value === "") {
  //     setMaxDate(null);
  //   } else {
  //     setMaxDate(props.maxDate);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [props.maxDate]);
  useEffect(() => {
    if (props.clearDob === true) {
      handleInput(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.clearDob]);
  /**
   * onChange of datepicker
   */
  const handleInput = (event) => {
    if (event === null) {
      setValues(null);
    }
    // setValues(formatDate(event))
    if (props.onChange) {
      const target = { type: 'date', checked: null, value: event };
      props.onChange({ target });
    }
  };

  return (
    <FormControl style={{ width: '100%' }}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <DateField
          // defaultValue={null}
          // disableToolbar
          fullWidth
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
          //   TextFieldComponent={TextFieldComponent}

          // InputProps={{
          //   inputComponent: TextMaskCustom,
          // }}
          InputLabelProps={{
            shrink: true,
          }}
          maxDate={props.maxDate}
          {...props}
          // showMask
          // error={false}
          // helperText={''}
          onChange={handleInput}
          variant={'inline'}
          inputVariant={props.inputVariant ? props.inputVariant : 'outlined'}
          value={value}
          // format="MM/dd/yyyy"
          // placeholder={"MM/DD/YYYY"}
          format="MM/dd/yy"
          placeholder={'MM/DD/YY'}
          showtodaybutton="true"
          autoOk={true}
        />
      </MuiPickersUtilsProvider>
    </FormControl>
  );
}

export default DobPicker;
