export const Apis = {
  //Coder APIs
  coder_loginApi: "trillium-clinic-service/v1/authentication/clinic/user/login",
  // coder_coderListApi: "trillium-internal-service/v1/coderPortal/list/range",
  coder_coderListApi: "trillium-internal-service/v1/ArManagement/list/range",
  export_details: "trillium-internal-service/v1/ArManagement/export",
  // coder_getCoderApi: 'trillium-internal-service/v1/coderPortal',
  coder_getCoderApi: "trillium-internal-service/v1/coderPortal",
  coder_icdSearchApi: "trillium-clinic-service/v1/icd/icdcode",
  coder_icdSaveApi: "trillium-clinic-service/v1/icd",
  coder_cptSearchApi: "trillium-clinic-service/v1/cliniccpt/cptcode",
  coder_logInfoSaveApi: "trillium-internal-service/v1/log/",
  coder_cptSaveApi: "trillium-clinic-service/v1/cliniccpt",
  coder_coderSaveApi:
    "trillium-internal-service/v1/coderPortal/addTestProcedureDetails",
  coder_loginInfoApi: "trillium-internal-service/v1/common/priorAuth/cache",
  coder_fileUploadApi: "trillium-internal-service/v1/coderPortal/upload",
  coder_downloadFileApi: "trillium-internal-service/v1/coderPortal",
  coder_logListApi: "trillium-internal-service/v1/log/all/list",
  coder_bulkUpdateApi: "trillium-internal-service/v1/coderPortal/list",
  coder_rowUpdateApi: "trillium-internal-service/v1/coderPortal/list",
  coder_icdKeywordSearchApi: "trillium-clinic-service/v1/icd/search",
  coder_cptDeleteApi: "trillium-internal-service/v1/priorAuth/cpt",
  coder_icdChildApi: "trillium-clinic-service/v1/icd/tree/search",
  coder_paHistoryApi: "trillium-internal-service/v1/priorAuth/list",
  coder_searchPayorApi: "trillium-clinic-service/v1/payer/search",
  coder_patientSaveApi: "trillium-clinic-service/v1/patient/",
  coder_policyHolderSaveApi: "trillium-clinic-service/v1/policyHolder/",
  coder_policySaveApi: "trillium-clinic-service/v1/policy/",

  //Appoinment APIs
  coder_searchPatientApi: "trillium-clinic-service/v1/patient/search",
  coder_cacheApi: "trillium-internal-service/v1/common/list",
  coder_AppointmentSaveApi: "trillium-clinic-service/v1/appointment",
  coder_payerSaveApi: "trillium-clinic-service/v1/payer",
};
