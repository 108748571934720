import React, { createContext, useState } from 'react';

export const filterContext = createContext();

function ContextProvider({ children }) {
  const [selectedItem, setSelectedItem] = useState({
    dos: true,
    patientName: true,
    patientDob: true,
    clinicName: true,
    providerName: true,
    appointmentType: true,
    payor: true,
    dueDate: true,
    owner: true,
    status: true,
  });
  const [headCells, setHeadCells] = useState([
    { id: 'dos', label: 'DOS' },
    { id: 'patientName', label: 'Patient Name' },
    { id: 'patientDob', label: 'DOB' },
    { id: 'clinicName', label: 'Clinic Name' },
    { id: 'providerName', label: 'Provider' },
    { id: 'appointmentType', label: 'Type' },
    { id: 'payor', label: 'Payor' },
    { id: 'dueDate', label: 'Due Date' },
    { id: 'owner', label: 'Owner' },
    { id: 'status', label: 'Status' },
  ]);

  return (
    <filterContext.Provider
      value={{ selectedItem, setSelectedItem, headCells, setHeadCells }}
    >
      {children}
    </filterContext.Provider>
  );
}

export default ContextProvider;
