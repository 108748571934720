import React, { useState } from 'react';
import { connect } from 'react-redux';
import DataTableFilter from 'Components/dataTableFilter';
import DataTable from 'Components/DataTable';
import { toast } from 'react-hot-toast';
import Header from 'Components/Header';
import { useEffect } from 'react';
import './style.css';
function TableContainer(props) {
  const [checkBoxArray, setCheckBoxArray] = useState([]);

  useEffect(() => {
    localStorage.removeItem('currentCoder');
    localStorage.removeItem('physicianInfo');
  }, []);

  return (
    <>
      <Header backButton={false} />
      <div className="data_table_filter_div">
        <DataTableFilter checkBoxArray={checkBoxArray} />
      </div>
      <div className="main_table">
        <DataTable
          passRowData={(e) => props.passRowData(e)}
          checkBoxArray={(e) => setCheckBoxArray(e)}
          visible={(e) => props.visible(e)}
          backButtonClicked={localStorage.getItem('backButtonClicked')}
        />
        {/* <Appointment handleClose={() => handleClose()} open={open} /> */}
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  AppointmentData: state.Appointment,
});

const mapDispatchToProps = (dispatch) => ({});
TableContainer.propTypes = {};
export default connect(mapStateToProps, mapDispatchToProps)(TableContainer);
