import React, { useState } from 'react';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { setFilter, bulkUpdate } from 'Redux/CoderApis/coder.actions';
import {
  getCache,
  exportData,
  exportDataResponse,
} from 'Redux/Appointment/apt.actions';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import DobPicker from 'Components/dobPicker';
import Dropdown from 'Components/Dropdown';
import { dateToyyymmdd } from 'HelperFunctions/TimeDateTimestamp';
import './styles.css';
import toast from 'react-hot-toast';

const DataTableFilter = (props) => {
  const [clinicList, setClinicList] = useState([]);
  const [provider, setProvider] = useState([]);
  const [status, setStatus] = useState([]);
  const [payor, setPayor] = useState([]);
  const [result, setResult] = useState([]);
  const [owner, setOwner] = useState([]);
  const [checkBoxDto, setCheckboxDto] = useState([]);
  const [forms, setForms] = useState({
    clinic: '',
    provider: '',
    status: '',
    payor: '',
    // startDate: new Date().setFullYear(new Date().getFullYear() - 1),
    startDate: new Date(),
    endDate: new Date(),
    owner: '',
    dueDate: '',
  });
  const [actionForm, setActionForm] = useState({
    // status: '',
    owner: '',
  });

  useEffect(() => {
    let f = props.coderData.filterForm;
    console.log(f);
    if (f) {
      setForms({
        clinic: f.clinic,
        provider: f.provider,
        status: f.status,
        // result: f.result,
        payor: f.payor,
        startDate: f.startDate ? f.startDate : new Date(),
        // : new Date().setFullYear(new Date().getFullYear() - 1),
        endDate: f.endDate ? f.endDate : new Date(),
        owner: f.owner,
        dueDate: f.dueDate ? f.dueDate : '',
      });
    }
  }, [props.coderData.filterForm]);

  useEffect(() => {
    const cache = JSON.parse(localStorage.getItem('cache'));
    let clinicList = [];
    let status = [];
    let result = [];
    let owner = [];
    let payor = [];
    cache.clinicDtoList.forEach((x) => {
      clinicList.push({
        name: x.name,
        value: x.clinicId,
      });
    });

    cache.statusList.forEach((x) => {
      status.push({
        name: x.description,
        value: parseInt(x.value),
      });
    });
    cache.payorPortalList.forEach((x) => {
      payor.push({
        name: x.description,
        value: parseInt(x.value),
      });
    });
    cache.userDtoList.forEach((x) => {
      owner.push({
        name: x.fullName,
        value: x.userId,
      });
    });
    setClinicList(clinicList);
    setStatus(status);
    setResult(result);
    setOwner(owner);
    props.getCache(cache.clinicDtoList[0].clinicId);
    // setPayor(payor);
  }, []);

  useEffect(() => {
    if (props.checkBoxArray && props.checkBoxArray.length > 0) {
      let a = [];
      props.checkBoxArray.forEach((x) => {
        if (x.isSelected === true) {
          a.push({
            claimId: x.claimId,
            visitId: 0,
            medicalCodingId: x.medicalCodingId,
            owner: '',
            ownerId: x.ownerId,
            resultId: x.resultId,
            statusId: x.statusId,
          });
        }
      });
      setCheckboxDto(a);
    }
  }, [props.checkBoxArray]);

  const downloadFiles = (content, fileName, contentType) => {
    if (!contentType) contentType = 'application/octet-stream';
    var a = document.createElement('a');
    var blob = new Blob([content], { type: 'application/vnd.ms-excel' });
    a.href = URL.createObjectURL(blob);
    a.download = fileName;
    a.click();
    blob = null;
  };

  useEffect(() => {
    if (props.AppointmentData.exportFile) {
      let blobData = props.AppointmentData.exportFile.response;
      let fileName = 'ArManagement_info';
      let fileType = '.jpeg';
      downloadFiles(blobData, fileName, fileType);
      props.exportDataResponse(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.AppointmentData.exportFile]);

  const exportFunction = () => {
    console.log(forms);
    let f = forms;
    let statusFilter = [];
    let ownerFilter = [];
    let resultFilter = [];
    // f.status.forEach((x) => {
    //   statusFilter.push(status.find((y) => y.name === x)["value"]);
    // });
    // f.result.forEach((x) => {
    //   resultFilter.push(result.find((y) => y.name === x)["value"]);
    // });
    // f.owner.forEach((x) => {
    //   ownerFilter.push(owner.find((y) => y.name === x)["value"]);
    // });
    props.exportData({
      startDate: dateToyyymmdd(f.startDate),
      endDate: dateToyyymmdd(f.endDate),
      dueDate: f.dueDate ? dateToyyymmdd(f.dueDate) : '',
      ownerIds: f.owner ? f.owner : '',
      providerId: f.provider ? f.provider : '',
      // resultIds: f.result ? resultFilter : "",
      statusIds: f.status ? f.status : '',
      clinicId: f.clinic ? f.clinic : '',
      payorId: f.payorId ? f.payorId : '',
    });
  };

  const handleCheckbox = () => {
    let a = [];
    checkBoxDto.forEach((x) => {
      a.push({
        claimId: x.claimId,
        owner: actionForm.owner
          ? owner.find((x) => x.value === actionForm.owner).name
          : '',
        ownerId: actionForm.owner,
        // resultId: actionForm.result,
        statusId: actionForm.status ? actionForm.status : null,
      });
    });
    props.bulkUpdate(a);
  };

  useEffect(() => {
    if (props.AppointmentData.cache) {
      let Response = props.AppointmentData.cache.response;
      console.log('apptData', forms.clinic);
      if (Response.responseCode === 0) {
        let data = Response.data;
        // console.log('Cachee', data);
        let provider = [];
        let payorList = [];
        data.providerDtoList.forEach((x) => {
          provider.push({
            name: x.fullName,
            value: x.id,
          });
        });
        data.payorDtoList.forEach((x) => {
          payorList.push({
            name: x.payerName,
            value: x.id,
          });
        });
        if (forms.clinic) {
          setProvider(provider);
        }
        setPayor(payorList);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.AppointmentData.cache]);

  useEffect(() => {
    if (props.coderData.bulkUpdateResponse) {
      let response = props.coderData.bulkUpdateResponse.response;
      if (response.responseCode === 0) {
        toast.success('Updation Success');
        props.coderData.bulkUpdateResponse = null;
        setActionForm({ owner: '' });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.coderData.bulkUpdateResponse]);

  return (
    <div>
      <div className="filter_container">
        <div></div>
        <div className="filters">
          <div className="filter_field">
            <Dropdown
              tabIndex={3}
              options={[{ name: 'All Clinics', value: null }, ...clinicList]}
              label="Clinic"
              onChange={(e) => {
                setForms({ ...forms, clinic: e.target.value });
                if (e.target.value) {
                  props.getCache(e.target.value);
                }
              }}
              value={forms.clinic}
            />
          </div>
          <div className="filter_field">
            <Dropdown
              options={[{ name: 'All Provider', value: null }, ...provider]}
              label="Provider"
              onChange={(e) => setForms({ ...forms, provider: e.target.value })}
              value={forms.provider}
            />
          </div>
          <div className="filter_field">
            <Dropdown
              tabIndex={3}
              options={[{ name: 'All Status', value: null }, ...status]}
              label="Status"
              onChange={(e) => setForms({ ...forms, status: e.target.value })}
              value={forms.status}
            />
          </div>
          {/* <div className="filter_field">
          <Dropdown
            tabIndex={3}
            options={[{ name: 'All Result', value: null }, ...result]}
            label="Result"
            onChange={(e) => setForms({ ...forms, result: e.target.value })}
            value={forms.result}
          />
        </div> */}
          <div className="filter_field_date">
            <DobPicker
              label="DOS Start"
              inputVariant={'standard'}
              onChange={(e) =>
                setForms({ ...forms, startDate: e.target.value })
              }
              value={forms.startDate}
            />
          </div>
          <div className="filter_field_date">
            <DobPicker
              label="DOS End"
              inputVariant={'standard'}
              value={forms.endDate}
              onChange={(e) => setForms({ ...forms, endDate: e.target.value })}
              minDate={forms.startDate}
            />
          </div>
          <div className="filter_field">
            <Dropdown
              tabIndex={3}
              options={[{ name: 'All Payor', value: null }, ...payor]}
              label="Payor"
              value={forms.payor}
              onChange={(e) => setForms({ ...forms, payor: e.target.value })}
            />
          </div>
          <div className="filter_field">
            <Dropdown
              tabIndex={3}
              options={[{ name: 'All Owner', value: null }, ...owner]}
              label="Owner"
              value={forms.owner}
              onChange={(e) => setForms({ ...forms, owner: e.target.value })}
            />
          </div>
          <div className="filter_field_date">
            <DobPicker
              label="Due Date"
              inputVariant={'standard'}
              onChange={(e) => setForms({ ...forms, dueDate: e.target.value })}
              value={forms.dueDate}
            />
          </div>
          <div className="filter_field_btn filter_btn">
            <Button
              variant="contained"
              color="primary"
              onClick={() => props.setFilter(forms)}
            >
              Filter
            </Button>
            <Button
              style={{ marginLeft: '15px' }}
              variant="contained"
              color="primary"
              onClick={exportFunction}
            >
              Export
            </Button>
          </div>
        </div>
        <div className="update_field">
          <div className="filter_field">
            <Dropdown
              tabIndex={3}
              options={[{ name: 'All Owner', value: null }, ...owner]}
              label="Owner"
              value={actionForm.owner ? actionForm.owner : null}
              onChange={(e) =>
                setActionForm({ ...actionForm, owner: e.target.value })
              }
            />
          </div>
          {/* <div className="filter_field">
            <Dropdown
              tabIndex={3}
              options={[{ name: 'All Status', value: null }, ...status]}
              label="Status"
              value={actionForm.status}
              onChange={(e) =>
                setActionForm({ ...actionForm, status: e.target.value })
              }
            />
          </div> */}
          <div className="filter_field_btn">
            <Button
              disabled={checkBoxDto.length > 0 ? false : true}
              variant="contained"
              color="primary"
              onClick={handleCheckbox}
            >
              Update
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  coderData: state.coder,
  AppointmentData: state.Appointment,
});

const mapDispatchToProps = (dispatch) => ({
  setFilter: (values) => dispatch(setFilter(values)),
  bulkUpdate: (values) => dispatch(bulkUpdate(values)),
  getCache: (values) => dispatch(getCache(values)),
  exportData: (values) => dispatch(exportData(values)),
  exportDataResponse: (values) => dispatch(exportDataResponse(values)),
});
DataTableFilter.propTypes = {
  setFilter: PropTypes.func,
  bulkUpdate: PropTypes.func,
  getCache: PropTypes.func,
  exportData: PropTypes.func,
  exportDataResponse: PropTypes.func,
};
export default connect(mapStateToProps, mapDispatchToProps)(DataTableFilter);
