/**
 * @fileOverview Manages types of the particular action in the redux
 * @author Akhil Francis <akhil@trillium-technologies.com>
 * @example export const TYPE_NAME = 'VALUE'
 */
/**
 * @type {string}
 */
export const REQUEST_CANCEL = 'REQUEST_CANCEL';
export const LOGIN = 'LOGIN';
export const LOGIN_RES = 'LOGIN_RES';
export const CLAIM_PREVIEW = 'CLAIM_PREVIEW';
export const CLAIM_PREVIEW_RES = 'CLAIM_PREVIEW_RES';
export const CODER_LIST = 'CODER_LIST';
export const CODER_LIST_RES = 'CODER_LIST_RES';
export const GET_CODER = 'GET_CODER';
export const GET_CODER_RES = 'GET_CODER_RES';
export const SET_FILTER = 'SET_FILTER';
export const ICD_SEARCH = 'ICD_SEARCH';
export const ICD_SEARCH_RES = 'ICD_SEARCH_RES';
export const CPT_SEARCH = 'CPT_SEARCH';
export const CPT_SEARCH_RES = 'CPT_SEARCH_RES';
export const ICD_SAVE = 'ICD_SAVE';
export const ICD_SAVE_RES = 'ICD_SAVE_RES';
export const CPT_SAVE = 'CPT_SAVE';
export const CPT_SAVE_RES = 'CPT_SAVE_RES';
export const LOG_INFO_SAVE = 'LOG_INFO_SAVE';
export const LOG_INFO_SAVE_RES = 'LOG_INFO_SAVE_RES';
export const CODER_SAVE = 'CODER_SAVE';
export const CODER_SAVE_RES = 'CODER_SAVE_RES';
export const LOGIN_INFO = 'LOGIN_INFO';
export const LOGIN_INFO_RES = 'LOGIN_INFO_RES';
export const FILE_UPLOAD = 'FILE_UPLOAD';
export const FILE_UPLOAD_RES = 'FILE_UPLOAD_RES';
export const DOWNLOAD = 'DOWNLOAD';
export const DOWNLOAD_RES = 'DOWNLOAD_RES';
export const LOGLIST = 'LOGLIST';
export const LOGLIST_RES = 'LOGLIST_RES';
export const BULK_UPDATE = 'BULK_UPDATE';
export const BULK_UPDATE_RES = 'BULK_UPDATE_RES';
export const ROW_UPDATE = 'ROW_UPDATE';
export const ROW_UPDATE_RES = 'ROW_UPDATE_RES';
export const ICD_KEYWORD_SEARCH = 'ICD_KEYWORD_SEARCH';
export const ICD_KEYWORD_SEARCH_RES = 'ICD_KEYWORD_SEARCH_RES';
export const CPT_DELETE = 'CPT_DELETE';
export const CPT_DELETE_RES = 'CPT_DELETE_RES';
export const ICD_CHILD = 'ICD_CHILD';
export const ICD_CHILD_RES = 'ICD_CHILD_RES';
export const PA_HISTORY = 'PA_HISTORY';
export const PA_HISTORY_RES = 'PA_HISTORY_RES';
export const SEARCH_PAYOR = 'SEARCH_PAYOR';
export const SEARCH_PAYOR_RES = 'SEARCH_PAYOR_RES';
export const PATIENT_SAVE = 'PATIENT_SAVE';
export const PATIENT_SAVE_RES = 'PATIENT_SAVE_RES';
export const POLICYHOLDER_SAVE = 'POLICYHOLDER_SAVE';
export const POLICYHOLDER_SAVE_RES = 'POLICYHOLDER_SAVE_RES';
export const POLICY_SAVE = 'POLICY_SAVE';
export const POLICY_SAVE_RES = 'POLICY_SAVE_RES';
