import { ofType } from 'redux-observable';
import { switchMap, map, takeUntil } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';
import { ajax as UtilsAjax } from 'Utils';
import { objectToUrl } from 'Utils/ajax-utils';
import environment from '../../environments/environment';
import {
  BULK_UPDATE,
  LOG_INFO_SAVE,
  CPT_SAVE,
  CPT_SEARCH,
  DOWNLOAD,
  FILE_UPLOAD,
  GET_CODER,
  ICD_SAVE,
  ICD_SEARCH,
  LOGIN,
  LOGIN_INFO,
  LOGLIST,
  CODER_LIST,
  CODER_SAVE,
  REQUEST_CANCEL,
  ROW_UPDATE,
  ICD_KEYWORD_SEARCH,
  CPT_DELETE,
  ICD_CHILD,
  PA_HISTORY,
  SEARCH_PAYOR,
  PATIENT_SAVE,
  POLICYHOLDER_SAVE,
  POLICY_SAVE,
  CLAIM_PREVIEW,
} from './coder.types';
import {
  bulkUpdateResponse,
  logInfoSaveResponse,
  cptSaveResponse,
  cptSearchResponse,
  fileDownloadResponse,
  fileUploadResponse,
  getCoderResponse,
  icdSaveResponse,
  icdSearchResponse,
  loginInfoResponse,
  loginResponse,
  loglistResponse,
  coderListResponse,
  coderSaveRes,
  icdkeywordResponse,
  cptDeleteResponse,
  icdChildResponse,
  paHistoryRes,
  searchPayoRes,
  patientSaveRes,
  policyHolderSaveRes,
  policySaveRes,
  getClaimPreviewResponse,
} from './coder.actions';
import { Apis } from 'Redux/APIs';
const { baseUrl } = environment;
const { ClinicBaseUrl } = environment;
const CoderEpic = {};

/**
 * @fileOverview Manages the APIs w.r.t action in the redux
 * @author  Akhil Francis <akhil@trillium-technologies.com>
 * @example Name of the epic = (params) =>
 */

/**
 * Login
 * @description Calling the login API for a user to login
 * @param action$
 */
CoderEpic.loginApi = (action$) =>
  action$.pipe(
    ofType(LOGIN),
    switchMap((action) =>
      ajax({
        headers: {
          'application-id': 8,
          'Content-Type': 'application/json',
        },
        url: `${ClinicBaseUrl}/${Apis.coder_loginApi}`,
        method: 'POST',
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => loginResponse(response)
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        )
      )
    )
  );

CoderEpic.coderListApi = (action$) =>
  action$.pipe(
    ofType(CODER_LIST),
    switchMap((action) =>
      UtilsAjax({
        url: objectToUrl(
          `${ClinicBaseUrl}/${Apis.coder_coderListApi}`,
          action.payload
        ),
        method: 'GET',
      }).pipe(
        map(
          (response) => coderListResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL)))
        )
      )
    )
  );

CoderEpic.getCoderApi = (action$) =>
  action$.pipe(
    ofType(GET_CODER),
    switchMap((action) =>
      UtilsAjax({
        url: `${ClinicBaseUrl}/${Apis.coder_getCoderApi}/${action.payload.claimId}?codingId=${action.payload.codingId}`,
        method: 'GET',
      }).pipe(
        map(
          (response) => getCoderResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL)))
        )
      )
    )
  );
CoderEpic.icdSearchApi = (action$) =>
  action$.pipe(
    ofType(ICD_SEARCH),
    switchMap((action) =>
      UtilsAjax({
        url: `${ClinicBaseUrl}/${Apis.coder_icdSearchApi}/${action.payload.s}?clinicId=${action.payload.cid}`,
        method: 'GET',
      }).pipe(
        map(
          (response) => icdSearchResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL)))
        )
      )
    )
  );
CoderEpic.icdSaveApi = (action$) =>
  action$.pipe(
    ofType(ICD_SAVE),
    switchMap((action) =>
      UtilsAjax({
        url: `${ClinicBaseUrl}/${Apis.coder_icdSaveApi}/`,
        method: 'POST',
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => icdSaveResponse(response)
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        )
      )
    )
  );

CoderEpic.cptSearchApi = (action$) =>
  action$.pipe(
    ofType(CPT_SEARCH),
    switchMap((action) =>
      UtilsAjax({
        url: `${ClinicBaseUrl}/${Apis.coder_cptSearchApi}/${action.payload.s}?clinicId=${action.payload.cid}`,
        method: 'GET',
      }).pipe(
        map(
          (response) => cptSearchResponse(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL)))
        )
      )
    )
  );
CoderEpic.logInfoSaveApi = (action$) =>
  action$.pipe(
    ofType(LOG_INFO_SAVE),
    switchMap((action) =>
      UtilsAjax({
        url: `${ClinicBaseUrl}/trillium-internal-service/v1/log/`,
        method: 'POST',
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => logInfoSaveResponse(response)
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        )
      )
    )
  );
CoderEpic.cptSaveApi = (action$) =>
  action$.pipe(
    ofType(CPT_SAVE),
    switchMap((action) =>
      UtilsAjax({
        url: `${ClinicBaseUrl}/${Apis.coder_cptSaveApi}/`,
        method: 'POST',
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => cptSaveResponse(response)
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        )
      )
    )
  );
CoderEpic.coderSaveApi = (action$) =>
  action$.pipe(
    ofType(CODER_SAVE),
    switchMap((action) =>
      UtilsAjax({
        url: `${ClinicBaseUrl}/${Apis.coder_coderSaveApi}`,
        method: 'POST',
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => coderSaveRes(response)
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        )
      )
    )
  );
CoderEpic.loginInfoApi = (action$) =>
  action$.pipe(
    ofType(LOGIN_INFO),
    switchMap((action) =>
      UtilsAjax({
        headers: {
          'application-id': 8,
          'Content-Type': 'application/json',
        },
        url: `${ClinicBaseUrl}/trillium-internal-service/v1/common/cache`,
        // url: `${ClinicBaseUrl}/${Apis.coder_loginInfoApi}?applicationId=${8}`,

        method: 'GET',
      }).pipe(
        map(
          (response) => loginInfoResponse(response)
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        )
      )
    )
  );
CoderEpic.fileUploadApi = (action$) =>
  action$.pipe(
    ofType(FILE_UPLOAD),
    switchMap((action) =>
      ajax({
        headers: {
          'application-id': 7,
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
        url: `${ClinicBaseUrl}/${Apis.coder_fileUploadApi}/${action.payload.coderId}`,
        method: 'POST',
        body: action.payload.data,
      }).pipe(
        map(
          (response) => fileUploadResponse(response)
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        )
      )
    )
  );
CoderEpic.downloadFileApi = (action$) =>
  action$.pipe(
    ofType(DOWNLOAD),
    switchMap((action) =>
      ajax({
        url: `${ClinicBaseUrl}/${Apis.coder_downloadFileApi}/download?claimId=${action.payload.claimId}&clinicId=${action.payload.clinicId}&codingId=${action.payload.codingId}&fileId=${action.payload.fileId}&fileName=${action.payload.name}`,
        method: 'GET',
        responseType: 'blob',
      }).pipe(
        map(
          (response) => fileDownloadResponse(response)
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        )
      )
    )
  );

CoderEpic.logListApi = (action$) =>
  action$.pipe(
    ofType(LOGLIST),
    switchMap((action) =>
      UtilsAjax({
        url: `${ClinicBaseUrl}/${Apis.coder_logListApi}/${action.payload.claimId}`,
        method: 'GET',
      }).pipe(
        map(
          (response) => loglistResponse(response)
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        )
      )
    )
  );

CoderEpic.bulkUpdateApi = (action$) =>
  action$.pipe(
    ofType(BULK_UPDATE),
    switchMap((action) =>
      UtilsAjax({
        // url: `${baseUrl}/${Apis.coder_bulkUpdateApi}`,
        url: `${ClinicBaseUrl}/trillium-internal-service/v1/ArManagement/list`,
        // url: `${baseUrl}/trillium-internal-service/trillium-internal-service/v1/ArManagement/list`,
        method: 'PUT',
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => bulkUpdateResponse(response)
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        )
      )
    )
  );
CoderEpic.rowUpdateApi = (action$) =>
  action$.pipe(
    ofType(ROW_UPDATE),
    switchMap((action) =>
      UtilsAjax({
        url: `${ClinicBaseUrl}/${Apis.coder_rowUpdateApi}`,
        method: 'PUT',
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => bulkUpdateResponse(response)
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        )
      )
    )
  );
CoderEpic.icdKeywordSearchApi = (action$) =>
  action$.pipe(
    ofType(ICD_KEYWORD_SEARCH),
    switchMap((action) =>
      UtilsAjax({
        url: `${ClinicBaseUrl}/${Apis.coder_icdKeywordSearchApi}?searchField=%20&searchParam=${action.payload}`,
        method: 'GET',
      }).pipe(
        map(
          (response) => icdkeywordResponse(response)
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        )
      )
    )
  );

CoderEpic.cptDeleteApi = (action$) =>
  action$.pipe(
    ofType(CPT_DELETE),
    switchMap((action) =>
      UtilsAjax({
        url: `${ClinicBaseUrl}/${Apis.coder_cptDeleteApi}/${action.payload}`,
        method: 'DELETE',
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => cptDeleteResponse(response)
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        )
      )
    )
  );
CoderEpic.icdChildApi = (action$) =>
  action$.pipe(
    ofType(ICD_CHILD),
    switchMap((action) =>
      UtilsAjax({
        url: `${ClinicBaseUrl}/${Apis.coder_icdChildApi}?parentId=${action.payload}`,
        method: 'GET',
      }).pipe(
        map(
          (response) => icdChildResponse(response)
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        )
      )
    )
  );

CoderEpic.paHistoryApi = (action$) =>
  action$.pipe(
    ofType(PA_HISTORY),
    switchMap((action) =>
      UtilsAjax({
        url: `${ClinicBaseUrl}/${Apis.coder_paHistoryApi}/${action.payload}`,
        method: 'GET',
      }).pipe(
        map(
          (response) => paHistoryRes(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL)))
        )
      )
    )
  );
CoderEpic.searchPayorApi = (action$) =>
  action$.pipe(
    ofType(SEARCH_PAYOR),
    switchMap((action) =>
      UtilsAjax({
        url: `${ClinicBaseUrl}/${Apis.coder_searchPayorApi}/name?name=${action.payload.str}&clinicId=${action.payload.id}`,
        method: 'GET',
      }).pipe(
        map(
          (response) => searchPayoRes(response),
          takeUntil(action$.pipe(ofType(REQUEST_CANCEL)))
        )
      )
    )
  );
CoderEpic.patientSaveApi = (action$) =>
  action$.pipe(
    ofType(PATIENT_SAVE),
    switchMap((action) =>
      UtilsAjax({
        url: `${ClinicBaseUrl}/${Apis.coder_patientSaveApi}`,
        method: 'POST',
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => patientSaveRes(response)
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        )
      )
    )
  );
CoderEpic.policyHolderSaveApi = (action$) =>
  action$.pipe(
    ofType(POLICYHOLDER_SAVE),
    switchMap((action) =>
      UtilsAjax({
        url: `${ClinicBaseUrl}/${Apis.coder_policyHolderSaveApi}`,
        method: 'POST',
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => policyHolderSaveRes(response)
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        )
      )
    )
  );
CoderEpic.policySaveApi = (action$) =>
  action$.pipe(
    ofType(POLICY_SAVE),
    switchMap((action) =>
      UtilsAjax({
        url: `${ClinicBaseUrl}/${Apis.coder_policySaveApi}`,
        method: 'POST',
        body: JSON.stringify(action.payload),
      }).pipe(
        map(
          (response) => policySaveRes(response)
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        )
      )
    )
  );
// CoderEpic.claimPreviewApi = (action$) =>
//   action$.pipe(
//     ofType(CLAIM_PREVIEW),
//     switchMap((action) =>
//       ajax({
//         url: `https://stagingnew.trillium.health//trillium-internal-service/v1/ArManagement/claim/preview?dos=${action.payload.dos}&patientId=${action.payload.patientId}`,
//         method: 'GET',
//         // responseType: 'blob',
//       }).pipe(
//         map(
//           (response) => getClaimPreviewResponse(response)
//           // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
//         )
//       )
//     )
//   );

CoderEpic.claimPreviewApi = (action$) =>
  action$.pipe(
    ofType(CLAIM_PREVIEW),
    switchMap((action) =>
      UtilsAjax({
        url: `${ClinicBaseUrl}/trillium-internal-service/v1/ArManagement/claim/preview?patientId=${action.payload.patientId}&claimId=${action.payload.claimId}&rankType=${action.payload.rankType}`,
        method: 'GET',
        responseType: 'blob',
      }).pipe(
        map(
          (response) => getClaimPreviewResponse(response)
          // takeUntil(action$.pipe(ofType(REQUEST_CANCEL))),
        )
      )
    )
  );

export default CoderEpic;
