import React from 'react';
import './style.css';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton } from '@material-ui/core';
import { CloseRounded } from '@material-ui/icons';
import { CallHistoryTable } from '../../../../../libs/call-history-table/src/';
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: '500 !important',
    paddingBottom: '1.6rem !important',
  },
}));

function CallHistory(props) {
  const classes = useStyles();
  return (
    <div className={'callhistory_main_div'}>
      <div className="homeScreen_notes_container">
        <IconButton onClick={() => props.onClose()}>
          <CloseRounded />
        </IconButton>
        <span>Notes</span>
        {/* <Typography className={classes.heading}>Notes</Typography> */}
      </div>
      <CallHistoryTable
        applicationId={props.applicationId}
        rowInfo={props.rowInfo}
      />
    </div>
  );
}

export default CallHistory;
