import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getCoder, getCoderResponse } from 'Redux/CoderApis/coder.actions';
import { getCache } from 'Redux/Appointment/apt.actions';
import PropTypes from 'prop-types';
import Header from 'Components/Header';
import CloseIcon from '@material-ui/icons/Close';
import CallInfo from 'Components/CallInfo';
import UploadInformation from 'Components/UploadInfo';
import Loader from 'Components/loader';
import { toast } from 'react-hot-toast';
import './EditStyle.css';
import {
  makeStyles,
  Button,
  Dialog,
  DialogContent,
  Popover,
  MenuItem,
} from '@material-ui/core';
import { ClaimSummary } from '../../../../../libs/claim-summary/src/index';
import { PatientSelector } from '../../../../../libs/patient-selector/src';
import { TabsPanel } from '../../../../../libs/tabs-panel/src';
import { ProcedureSection } from '../../../../../libs/procedure-section/src';
import classNames from 'classnames';
import { getClaimPreview } from '../../Redux/CoderApis/coder.actions';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(12),
    flexDirection: 'row',
    color: '#667ACD',
  },
  flexContainer: {
    display: 'flex',
    flexDirection: 'row',
    padding: ' 0',
  },
  loader: {
    display: 'flex',
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  tabs: {
    backgroundColor: 'white',
    height: '50%',
    width: '300px',
    borderRadius: '9px',
    border: '1px solid gray',
  },
}));

function EditContainer(props) {
  const classes = useStyles();
  const [loader, setLoader] = useState(false);
  const [patientInfo, setPatientInfo] = useState(null);
  const [appointmentType, setAppointmentType] = useState('');
  const [rowData, setRowData] = useState(null);
  const [value, setValue] = React.useState(0);
  const [patientList, setPatientList] = useState([]);
  const [isDrawerOpen, setIsDrawerOpen] = useState(true);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = React.useState(0);
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [previewFileUrl, setPreviewFileUrl] = useState('');
  const [dos, setDos] = useState('');
  const [isValidateOpen, setIsValidateOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedOption, setSelectedOption] = useState('');

  useEffect(() => {
    if (props.coderData.coderInfo) {
      let response = props.coderData.coderInfo.response;
      if (response.responseCode === 0) {
        setLoader(false);
        let data = response.data;
        setAppointmentType(data.appointmentType);
        setDos(data.dos);
        let phyInfo = {
          clinicName: data.clinicName,
          providerName: data.providerName,
          speciality: data.speciality,
          providerNpi: data.providerNpi,
          providerPhone: data.providerPhone,
          providerFax: data.providerFax,
          groupNpi: data.groupNpi,
          taxId: data.taxId,
          locationAddress: data.locationAddress,
          locationCity: data.locationCity,
          locationState: data.locationState,
          locationZip: data.locationZip,
          locationType: data.locationType,
        };
        setPhysicianInfo(phyInfo);
        localStorage.setItem('currentCoder', JSON.stringify(data));
        // localStorage.setItem("claimId", data.claimId);
      }
    }
  }, [props.coderData.coderInfo]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setRowData(patientList[newValue - 1]);
  };

  useEffect(() => {
    if (props.rowData) setRowData(props.rowData);
  }, [props.rowData]);

  const handleNext = () => {
    let currentCoder = JSON.parse(localStorage.getItem('currentSelection'));
    let currentList = JSON.parse(localStorage.getItem('currentList'));
    let index = currentList.findIndex(
      (x) => x.claimId === currentCoder.claimId
    );
    if (currentList[index + 1]) {
      props.setRowData(currentList[index + 1]);
      localStorage.setItem(
        'currentSelection',
        JSON.stringify(currentList[index + 1])
      );
    } else {
      toast.success('List in end');
    }
  };

  const handlePrev = () => {
    let currentCoder = JSON.parse(localStorage.getItem('currentSelection'));
    let currentList = JSON.parse(localStorage.getItem('currentList'));
    let index = currentList.findIndex(
      (x) => x.claimId === currentCoder.claimId
    );
    if (currentList[index - 1]) {
      props.setRowData(currentList[index - 1]);
      localStorage.setItem(
        'currentSelection',
        JSON.stringify(currentList[index - 1])
      );
    } else {
      toast.success('List in end');
    }
  };

  const convertDateFormat = (dateString) => {
    const splitDate = dateString.split('/');
    const newDate = `${splitDate[2]}-${splitDate[0]}-${splitDate[1]}`;
    return newDate;
  };

  // const handlePreview = () => {
  //   props.getClaimPreview({
  //     // dos: convertDateFormat(props.rowData.dos),
  //     claimId: props.rowData.claimId,
  //     patientId: props.rowData.patientId,
  //   });
  // };

  useEffect(() => {
    if (props.coderData.claimPreview) {
      const previewFile = props.coderData.claimPreview.response;
      const blob = new Blob([previewFile], { type: 'application/pdf' });
      const previewUrl = URL.createObjectURL(blob);
      setIsPreviewOpen(true);
      setPreviewFileUrl(previewUrl);
      props.coderData.claimPreview = null;
    }
  }, [props.coderData.claimPreview]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handlePreview = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  // const handlePreview = () => {
  //   props.getClaimPreview({
  //     // dos: convertDateFormat(props.rowData.dos),
  //     claimId: props.rowData.claimId,
  //     patientId: props.rowData.patientId,
  //   });
  // };

  const openPreview = (type) => {
    // console.log(type)
    if (type === 'primary') {
      props.getClaimPreview({
        claimId: props.rowData.claimId,
        patientId: props.rowData.patientId,
        rankType: 1,
      });
    } else {
      props.getClaimPreview({
        claimId: props.rowData.claimId,
        patientId: props.rowData.patientId,
        rankType: 2,
      });
    }
    setAnchorEl(null);
  };

  return (
    <div className="wrapper">
      <div className={isDrawerOpen ? 'leftTabOpen' : 'leftTabClosed'}>
        <div className="left_tab_inner_wrap">
          <PatientSelector
            totalCount={totalCount}
            setTotalCount={setTotalCount}
            setRowData={props.setRowData}
            rowData={props.rowData}
            applicationId={8}
            page={page}
            setPage={setPage}
            isDrawerOpen={isDrawerOpen}
            setIsDrawerOpen={setIsDrawerOpen}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </div>
      </div>
      <div className={isDrawerOpen ? 'container_min' : 'container_max'}>
        <div className="headerComponent">
          <Header
            name={'Claim Edit'}
            backButton={true}
            visible={(e) => props.visible(e)}
            patientInfo={patientInfo}
            appointmentType={appointmentType}
            // physicianInfo={physicianInfo}
            dos={dos}
            prevButton={true}
            nextButton={true}
            rowData={props.rowData}
            handlePrev={handlePrev}
            handleNext={handleNext}
            // disableNext={disableNext}
            // disablePrev={disablePrev}
          />
        </div>

        {loader === false && (
          <div className="mainContainer">
            <div
              className={classNames('main_edit_section', {
                main_edit_section_drawer_open: isDrawerOpen,
              })}
            >
              <div className="edit_section_button_container">
                <Button variant="contained" className="edit_ection_button">
                  eFile
                </Button>
                <Button variant="contained" className="edit_ection_button">
                  Print
                </Button>
                <Button variant="contained" className="edit_ection_button">
                  Fax
                </Button>
                {props.rowData.statusId !== 1 ? (
                  <Button
                    onClick={handlePreview}
                    variant="contained"
                    className="edit_ection_button"
                  >
                    Preview
                  </Button>
                ) : (
                  <Button
                    // onClick={handlePreview}
                    variant="contained"
                    className="edit_ection_button_disabled"
                  >
                    Preview
                  </Button>
                )}
                <Popover
                  open={Boolean(anchorEl)}
                  anchorEl={anchorEl}
                  onClose={handlePopoverClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  style={{ margin: '0 20px' }}
                >
                  {props.rowData.payerId > 0 ? (
                    <MenuItem onClick={() => openPreview('primary')}>
                      Primary
                    </MenuItem>
                  ) : (
                    <MenuItem disabled>
                      <span style={{ color: 'grey' }}>Primary</span>
                    </MenuItem>
                  )}
                  {props.rowData.secondaryPayerId > 0 ? (
                    <MenuItem onClick={() => openPreview('secondary')}>
                      Secondary
                    </MenuItem>
                  ) : (
                    <MenuItem disabled>
                      <span style={{ color: 'grey' }}>Secondary</span>
                    </MenuItem>
                  )}
                </Popover>
                <Button
                  onClick={() => setIsValidateOpen(true)}
                  variant="contained"
                  className="edit_ection_button"
                >
                  Validate
                </Button>
              </div>
              <div className="procedure_info_container">
                <ClaimSummary applicationId={8} rowInfo={props.rowData} />
              </div>
              <div className="procedure_info_container">
                {/* <ProcedureDetails /> */}
                <ProcedureSection applicationId={8} rowInfo={props.rowData} />
              </div>
              <div className="procedure_info_container">
                <CallInfo rowInfo={props.rowData} />
              </div>
              {/* <div className="procedure_info_container">
              <CallHistory />
            </div> */}
              {/* <div className="procedure_info_container">
              <UploadList />
            </div> */}
              <div className="procedure_info_container">
                <UploadInformation rowData={props.rowData} applicationId={8} />
              </div>
            </div>
            {/* <div className="main_edit_section"></div> */}
            <div
              className={classNames('rightTab', {
                right_tab_drawer_open: isDrawerOpen,
              })}
            >
              <TabsPanel
                applicationId={8}
                setRowInfo={props.setRowData}
                rowData={props.rowData}
              />
            </div>
          </div>
        )}
      </div>

      {loader && <Loader />}
      <Dialog
        className="scrollbarY--custom"
        maxWidth="md"
        // fullScreen
        fullWidth
        open={isPreviewOpen}
      >
        <DialogContent className="previewDialogContent">
          <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
            <div className="preview_closeBtn">
              <CloseIcon onClick={() => setIsPreviewOpen(false)} />
            </div>
            {/* <div style={{ cursor: 'pointer' }}>
              <FullscreenIcon onClick />
            </div> */}
          </div>
          <div className="preview">
            <object
              menu="false"
              width="100%"
              height="83%"
              data={previewFileUrl}
              type="application/pdf"
            ></object>
          </div>
        </DialogContent>
      </Dialog>
      {/* <Dialog
        className="scrollbarY--custom"
        maxWidth="md"
        // fullScreen
        // fullWidth
        open={isValidateOpen}
      >
        <DialogContent className="previewDialogContent">
          <Validate />
        </DialogContent>
      </Dialog> */}
    </div>
  );
}

const mapStateToProps = (state) => ({
  coderData: state.coder,
});

const mapDispatchToProps = (dispatch) => ({
  getCoder: (values) => dispatch(getCoder(values)),
  getCoderResponse: (values) => dispatch(getCoderResponse(values)),
  getCache: (values) => dispatch(getCache(values)),
  getClaimPreview: (values) => dispatch(getClaimPreview(values)),
});
EditContainer.propTypes = {
  getCoder: PropTypes.func,
  getCoderResponse: PropTypes.func,
  getCache: PropTypes.func,
  getClaimPreview: PropTypes.func,
};
export default connect(mapStateToProps, mapDispatchToProps)(EditContainer);
