import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import store from '../../Redux/store';
import Context from '../../context/AppContextProvider';
import { ContextProvider } from '../../context/contextProvider';
import Eob from './lib/index';

function App(props) {
  return (
    <Provider store={store}>
      <Context components={[ContextProvider]}>
        <Eob
          claimId={props.claimId}
          applicationId={props.applicationId}
          handleEobClose={props.handleEobClose}
        />
        ;
      </Context>
    </Provider>
  );
}

export const EobDialog = App;
