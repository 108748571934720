import React, { useState, useRef, useContext, useEffect } from 'react';
// import { useHistory } from "react-router-dom";
import { makeStyles, styled } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Link } from 'react-router-dom';
import { dateTommddyyy } from 'HelperFunctions/TimeDateTimestamp';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Menu,
} from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import FilterIcon from '@material-ui/icons/FilterList';
import { IconButton } from '@material-ui/core';
import { filterContext } from '../../context/contextProvider';
import { NavigateBefore } from '@material-ui/icons';
import './style.css';

/**
 * @fileOverview Manages Routing.
 * @component
 * @author Akhil Francis <akhil@tensaw.email>
 * @author Ridhik Govind <ridhik@tensaw.email>
 * @return {JSXElement}
 * @example
 * return(
 * <Header/>
 * )
 */

const useStyles = makeStyles((theme) => ({
  icon: {
    fontSize: '2rem !important',
  },
  popover: {
    pointerEvents: 'none',
  },
  popoverContent: {
    pointerEvents: 'auto',
  },
}));
const GreenCheckbox = styled(Checkbox)(({ theme }) => ({
  '& .MuiSvgIcon-root': {
    fill: `lightgray`,
  },
  '&.Mui-checked': {
    '& .MuiSvgIcon-root': {
      fill: `#139696`,
    },
  },
}));
function Header(props) {
  // const history = useHistory();
  const classes = useStyles();
  const [openedPopover, setOpenedPopover] = useState(false);
  const popoverAnchor = useRef(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openFilter = Boolean(anchorEl);
  const { headCells, setHeadCells, selectedItem, setSelectedItem } =
    useContext(filterContext);

  const popoverEnter = () => {
    setOpenedPopover(true);
  };

  const handleFilterColumn = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFilterColumnClose = () => {
    setAnchorEl(null);
  };

  const popoverLeave = () => {
    setOpenedPopover(false);
  };

  const handleFilterCheckbox = (e) => {
    setSelectedItem({ ...selectedItem, [e.target.name]: e.target.checked });
    let selected = { ...selectedItem, [e.target.name]: e.target.checked };
    localStorage.setItem('selectedItem', JSON.stringify(selected));
  };
  const logOutUser = () => {
    localStorage.clear();
    window.location.href = '/login';
  };

  useEffect(() => {
    let newItem = [];
    Object.keys(selectedItem).forEach((x) => {
      headCells.forEach((j) => {
        if (x === j.id) {
          newItem.push({
            ...j,
            status: selectedItem[x],
            // localStorage.selectedItem
            //   ? () => {
            //       let res = localStorage.getItem("selectedItem");
            //       return res[x];
            //     }
            //   : selectedItem[x],
          });
        }
      });
    });
    setHeadCells(newItem);
    newItem = [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItem]);

  const handleBackButton = () => {
    // props.visible(false);
    localStorage.setItem('backButtonClicked', Math.random());
  };

  return (
    <div className="clinic__header">
      <div
        className={
          props.nextButton
            ? 'clinic__header--inner header-left-insidePage'
            : 'header-left-homePage'
        }
      >
        <div className="inner--left">
          {props.backButton && (
            <Link to="/home">
              <IconButton onClick={handleBackButton}>
                <ArrowBackIcon />
              </IconButton>
            </Link>
          )}
          {/* {props.prevButton && (
            <Button
              onClick={() => props.handlePrev()}
              variant="outlined"
              color="primary"
              style={{ marginLeft: '25px', marginRight: '20px' }}
              disabled={props.disablePrev}
              startIcon={<NavigateBefore color="primary" />}
            >
              Previous
            </Button>
          )} */}
        </div>
        <div className="edit-screen">
          <span
            style={{
              fontSize: '22px',
              marginLeft: '10px',
              marginRight: '10px',
            }}
          >
            {props.name ? 'AR Mgmt Portal' : 'AR Mgmt Portal'}
          </span>
          <span>{props.rowData ? props.rowData.dos + ' ' : ''}</span>
          <span style={{ marginLeft: '10px' }}>
            {props.rowData
              ? ' | ' +
                props.rowData.patientFName +
                ' ' +
                props.rowData.patientLName
              : ''}
          </span>
          <span style={{ marginLeft: '10px' }}>
            {props.rowData && props.rowData.patientDob
              ? ' | ' + dateTommddyyy(props.rowData.patientDob)
              : ''}
          </span>
          <span style={{ marginLeft: '10px' }}>
            {props.rowData && props.rowData.appointmentType
              ? ' | ' + props.rowData.appointmentType
              : null}
          </span>
          <span style={{ marginLeft: '10px' }}>
            {props.rowData?.payorName ? ' | ' + props.rowData.payorName : ''}
          </span>
          {/* <span style={{ marginLeft: '20px' }}>
            {props.appointmentType && props.appointmentType + ' - '}
          </span> */}
          {/* <span>{props.dos}</span> */}
        </div>
      </div>
      <div
        className={
          props.nextButton
            ? 'clinic__header--inner header-right-insidePage'
            : 'header-right-homePage'
        }
      >
        <div className="buttonWrappers">
          {/* {props.nextButton && (
            <Button
              onClick={() => props.handleNext()}
              variant="outlined"
              color="primary"
              endIcon={<NavigateNextIcon color="primary" />}
              // style={{ marginRight: "150px" }}
              disabled={props.disableNext}
            >
              Next
            </Button>
          )} */}
        </div>
        <div className={props.nextButton ? 'hide' : 'filterColumn_icon'}>
          <IconButton
            id="column-filter"
            aria-controls={openFilter ? 'column-menu' : undefined}
            aria-expanded={openFilter ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleFilterColumn}
          >
            <FilterIcon />
          </IconButton>
        </div>
        <Menu
          className="columnMenu"
          id="column-menu"
          MenuListProps={{
            'aria-labelledby': 'column-filter',
          }}
          anchorEl={anchorEl}
          open={openFilter}
          onClose={handleFilterColumnClose}
          PaperProps={{
            style: {
              width: '55ch',
            },
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
        >
          <FormGroup className="column_filter_container">
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={0}>
                {headCells.map((x, i) => (
                  <Grid item xs={4} key={i}>
                    <FormControlLabel
                      className="column_filter_text"
                      control={<GreenCheckbox size="small" />}
                      onChange={(e) => handleFilterCheckbox(e)}
                      name={x.id}
                      label={x.label}
                      checked={x.status}
                      labelPlacement="end"
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>
          </FormGroup>
        </Menu>
        {/* {!props.nextButton && ( */}
          <div className="logout">
            <div
              className="clinic__profileCircle"
              ref={popoverAnchor}
              aria-owns="mouse-over-popover"
              aria-haspopup="true"
              onMouseEnter={popoverEnter}
              onMouseLeave={popoverLeave}
            >
              <span>AR</span>
            </div>
            <KeyboardArrowDownIcon color="primary" />
            <Popover
              id="mouse-over-popover"
              className={classes.popover}
              classes={{
                paper: classes.popoverContent,
              }}
              open={openedPopover}
              anchorEl={popoverAnchor.current}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              PaperProps={{
                onMouseEnter: popoverEnter,
                onMouseLeave: popoverLeave,
              }}
            >
              <div className="clinicHeader__popover">
                <div className="clinicHeader__popover--name">
                  AR Mgmt Portal
                </div>
                <div className="clinicHeader__popover--list">
                  <div className="logout" onClick={() => logOutUser()}>
                    Logout
                  </div>
                </div>
              </div>
            </Popover>
          </div>
        {/* )} */}
      </div>
    </div>
  );
}

export default Header;
