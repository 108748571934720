import React, { useEffect } from 'react';
import { connect } from 'react-redux';
// import { } from 'Redux/CoderApis/coder.actions';
import PropTypes from 'prop-types';
import { Button, CircularProgress, Grid, IconButton } from '@material-ui/core';
// import Dropdown from 'Components/Dropdown';
// import DobPicker from 'Components/dobPicker';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CptTable from './CptTable';
import Diagnosis from './Diagnosis';
import { useState } from 'react';
// import { dateToyyymmdd } from 'HelperFunctions/TimeDateTimestamp';
import { dateToyyymmdd } from '../../../helper-functions/src/lib/TimeDateTimestamp';
import toast from 'react-hot-toast';
import './style.css';
// import CoderHistoDialog from 'Components/CoderHistoDialog';
import {
  getProcedureInfo,
  procedureInfoSave,
  coderSave,
  getCoder,
  paHistory,
  getNotes,
  getApptNotes,
  getClaimSummary,
  getPaymentSummary,
} from '../../../Redux/CoderApis/main.actions';
import DiagnosisSkeleton from './Diagnosis/DiagnosisSkeleton';
import { Skeleton } from '@material-ui/lab';
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: '500 !important',
    // paddingBottom: '2rem !important',
  },
}));

function ProcedureDetails(props) {
  let currentInfo = JSON.parse(localStorage.getItem('currentCoder'));
  let clinicId = parseInt(localStorage.getItem('clinic_id'));
  const classes = useStyles();
  const [icdInfo, setIcdinfo] = useState({});
  const [cptInfo, setCptInfo] = useState({});
  const [apptType, setApptType] = useState('');
  const [sensitivity, setSensitivity] = useState('');
  const [isSaved, setIsSaved] = useState(false);
  const [dos, setDos] = useState('');
  const [clinicalInfo, setClinicalInfo] = useState('');
  const [receivedDate, setReceivedDate] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [sensitivityList, setSensitivityList] = useState('');
  const [paHistoryOpen, setPaHistoryOpen] = useState(false);

  function checkDuplicateICD(data) {
    const idSet = new Set();
    let hasDuplicate = false;
    for (let i = 1; i <= 8; i++) {
      const key = `icd${i}Id`;
      const id = data[key];
      if (id !== 0 && idSet.has(id)) {
        hasDuplicate = true;
        break;
      }
      idSet.add(id);
    }
    return hasDuplicate;
  }

  const convertDateFormat = (dateString) => {
    const splitDate = dateString.split('/');
    const newDate = `${splitDate[2]}-${splitDate[0]}-${splitDate[1]}`;
    return newDate;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (checkDuplicateICD(icdInfo)) {
      toast.error('Duplicate ICD entered');
      return;
    }
    setIsSaved(true);
    let ar = [];
    cptInfo.forEach((x, i) => {
      if (x.cptId) {
        ar.push({
          ...x,
          visitId: props.rowInfo.visitId ? props.rowInfo.visitId : 0,
          patientId: props.rowInfo.patientId ? props.rowInfo.patientId : 0,
          clinicId: props.rowInfo.clinicId,
          claimId: props.rowInfo.claimId,
          dos: convertDateFormat(props.rowInfo.dos),
          fee: parseFloat(x.fee).toFixed(2),
          amount: parseFloat(x.fee) * parseFloat(x.unit),
          sequenceNum: i + 1,
        });
      }
    });

    props.procedureInfoSave({
      data: {
        appointmentType: props.rowInfo.appointmentType,
        appointmentTypeId: 3,
        claimId: props.rowInfo.claimId,
        codingId: props.rowInfo.medicalCodingId,
        clinicId: props.rowInfo.clinicId,
        clinicalInfo: clinicalInfo ? clinicalInfo : '',
        receivedDate: dateToyyymmdd(props.rowInfo.receivedDate),
        sensitivityId: props.rowInfo.sensitivity,
        visitDiagnosisDto: {
          claimId: props.rowInfo.claimId,
          clinicId: props.rowInfo.clinicId,
          dx1: {
            clinicId: props.rowInfo.clinicId,
            icdCode: icdInfo.icd1Code,
            description: icdInfo.icd1Description,
            icdId: icdInfo.icd1Id,
          },
          dx2: {
            clinicId: props.rowInfo.clinicId,
            icdCode: icdInfo.icd2Code,
            description: icdInfo.icd2Description,
            icdId: icdInfo.icd2Id,
          },
          dx3: {
            clinicId: props.rowInfo.clinicId,
            icdCode: icdInfo.icd3Code,
            description: icdInfo.icd3Description,
            icdId: icdInfo.icd3Id,
          },
          dx4: {
            clinicId: props.rowInfo.clinicId,
            icdCode: icdInfo.icd4Code,
            description: icdInfo.icd4Description,
            icdId: icdInfo.icd4Id,
          },
          dx5: {
            clinicId: props.rowInfo.clinicId,
            icdCode: icdInfo.icd5Code,
            description: icdInfo.icd5Description,
            icdId: icdInfo.icd5Id,
          },
          dx6: {
            clinicId: props.rowInfo.clinicId,
            icdCode: icdInfo.icd6Code,
            description: icdInfo.icd6Description,
            icdId: icdInfo.icd6Id,
          },
          dx7: {
            clinicId: props.rowInfo.clinicId,
            icdCode: icdInfo.icd7Code,
            description: icdInfo.icd7Description,
            icdId: icdInfo.icd7Id,
          },
          dx8: {
            clinicId: props.rowInfo.clinicId,
            icdCode: icdInfo.icd8Code,
            description: icdInfo.icd8Description,
            icdId: icdInfo.icd8Id,
          },
          patientId: props.rowInfo.patientId ? props.rowInfo.patientId : 0,
          visitDiagnosisId: icdInfo.visitDiagnosisId,
          visitId: props.rowInfo.visitId ? props.rowInfo.visitId : 0,
        },
        visitId: props.rowInfo.visitId ? props.rowInfo.visitId : 0,
        visitServiceDtoList: ar,
      },
      applicationId: props.applicationId,
    });
  };

  useEffect(() => {
    if (props.rowInfo) {
      setIsLoading(true);
      const visitId = props.rowInfo.visitId;
      setReceivedDate(props.rowInfo.receivedDate);
      props.getProcedureInfo({
        visitId: visitId,
        applicationId: props.applicationId ? props.applicationId : 7,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.rowInfo]);

  useEffect(() => {
    if (
      props.coderData.procedureInfo &&
      props.coderData.procedureInfo.response
    ) {
      let response = props.coderData.procedureInfo.response;
      if (response.responseCode === 0) {
        let info = response.data;
        if (info.length === 0) {
          setClinicalInfo('');
          setApptType('');
          setDos('');
          setReceivedDate('');
          setSensitivity('');
          setIsLoading(false);
        } else {
          localStorage.setItem('procedureInfo', JSON.stringify(info));
          setClinicalInfo(info.clinicalInfo);
          setApptType(info.appointmentTypeId);
          setDos(info.dos);
          setReceivedDate(info.receivedDate);
          setSensitivity(info.sensitivity);
          setIsLoading(false);
        }
        // props.coderData.procedureInfo = null;
      }
    }
  }, [props.coderData.procedureInfo]);

  useEffect(() => {
    if (
      props.coderData.coderSaveResponse &&
      props.coderData.coderSaveResponse.response
    ) {
      let response = props.coderData.coderSaveResponse.response;
      if (response.responseCode === 0) {
        setIsSaved(false);
        toast.success('Procedure details saved');
        if (props.applicationId === 7 || props.applicationId === 8) {
          props.getClaimSummary({
            claimId: props.rowInfo.claimId,
            applicationId: props.applicationId,
          });
          props.getNotes({
            claimId: props.rowInfo.claimId,
            applicationId: props.applicationId,
            patientId: props.rowInfo.patientId,
          });
          props.getPaymentSummary(props.rowInfo.claimId);
        }

        if (props.applicationId === 6 || props.applicationId === 4) {
          props.getApptNotes({
            appointmentId: props.rowInfo.appointmentId,
            applicationId: props.applicationId,
            patientId: props.rowInfo.patientId,
          });
        }
        const visitId = props.rowInfo.visitId;
        setReceivedDate(props.rowInfo.receivedDate);
        props.getProcedureInfo({
          visitId: visitId,
          applicationId: props.applicationId ? props.applicationId : 7,
        });
        props.coderData.coderSaveResponse = null;
      } else {
        toast.error('Save Failed');
        props.coderData.coderSaveResponse = null;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.coderData.coderSaveResponse]);

  useEffect(() => {
    const cache = JSON.parse(localStorage.getItem('cache'));
    let sensitivityList = [];
    cache.sensitivityList.forEach((x) => {
      sensitivityList.push({
        name: x.description,
        value: x.value,
      });
    });
    setSensitivityList(sensitivityList);
  }, []);

  const paHistoryfn = () => {
    props.paHistory(props.rowInfo.patientId);
    setPaHistoryOpen(true);
  };

  return (
    <div className={'procedure_info_main_div'}>
      <form onSubmit={handleSubmit}>
        <div className="procedureHeader">
          <Typography className={classes.heading}>Charges</Typography>
          <div className="procedure_button">
            <Button variant="contained" color="primary" type="submit">
              {isSaved ? (
                <CircularProgress className="loader" size={24} />
              ) : (
                'Save'
              )}
            </Button>
            <Button
              onClick={() => setIsSaved(false)}
              variant="outlined"
              color="primary"
            >
              Cancel
            </Button>
          </div>
        </div>
        {/*
        <Grid container spacing={3}>
          <Grid item xs={2}>
            <DobPicker
              label="DOS"
              onChange={(e) => setDos(e.target.value)}
              value={dos}
            />
          </Grid>
          <Grid item xs={2}>
            <Dropdown
              variant="outlined"
              tabIndex={3}
              options={aptList}
              label="Appointment Type"
              value={apptType}
              onChange={(e) => setApptType(e.target.value)}
            />
          </Grid>
          <Grid item xs={2}>
            <DobPicker
              required
              label="Recieved Date"
              onChange={(e) => setReceivedDate(e.target.value)}
              value={receivedDate}
            />
          </Grid>
          <Grid item xs={2}>
            <Dropdown
              variant="outlined"
              tabIndex={3}
              options={sensitivityList}
              label="Sensitivity"
              value={sensitivity}
              onChange={(e) => setSensitivity(e.target.value)}
            />
          </Grid>
        </Grid> */}
        {isLoading ? (
          <DiagnosisSkeleton />
        ) : (
          <Diagnosis
            rowInfo={props.rowInfo}
            applicationId={props.applicationId}
            icdInformation={(e) => setIcdinfo(e)}
          />
        )}
        <div className="procedure_table_container">
          <div className="procedure_cpt_table">
            {isLoading ? (
              <Skeleton
                style={{ borderRadius: '10px' }}
                height={'150px'}
                variant="rect"
              />
            ) : (
              <CptTable
                icdInfo={icdInfo}
                dos={dos}
                applicationId={props.applicationId}
                rowInfo={props.rowInfo}
                cptInformation={(e) => setCptInfo(e)}
              />
            )}
          </div>
        </div>
        {/* <div className="procedure_button">
          <Button variant="contained" color="primary" type="submit">
            Save
          </Button>
          <Button variant="outlined" color="primary">
            Cancel
          </Button> */}
        {/* <Button variant="outlined" color="primary" onClick={paHistoryfn}>
            Appointment history
          </Button> */}
        {/* </div> */}
      </form>
      {/* <CoderHistoDialog
        open={paHistoryOpen}
        handleClose={() => setPaHistoryOpen(false)}
      /> */}
    </div>
  );
}

const mapStateToProps = (state) => ({
  coderData: state.coder,
  AppointmentData: state.Appointment,
});

const mapDispatchToProps = (dispatch) => ({
  procedureInfoSave: (values) => dispatch(procedureInfoSave(values)),
  getCoder: (values) => dispatch(getCoder(values)),
  paHistory: (values) => dispatch(paHistory(values)),
  getProcedureInfo: (values) => dispatch(getProcedureInfo(values)),
  getNotes: (values) => dispatch(getNotes(values)),
  getApptNotes: (values) => dispatch(getApptNotes(values)),
  getClaimSummary: (values) => dispatch(getClaimSummary(values)),
  getPaymentSummary: (values) => dispatch(getPaymentSummary(values)),
});
ProcedureDetails.propTypes = {
  icdSearch: PropTypes.func,
  getProcedureInfo: PropTypes.func,
  procedureInfoSave: PropTypes.func,
  getCoder: PropTypes.func,
  paHistory: PropTypes.func,
  getNotes: PropTypes.func,
  getApptNotes: PropTypes.func,
  getClaimSummary: PropTypes.func,
  getPaymentSummary: PropTypes.func,
};
export default connect(mapStateToProps, mapDispatchToProps)(ProcedureDetails);
