import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { getCoder } from "Redux/CoderApis/coder.actions";
import { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Dialog from "@material-ui/core/Dialog";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Typography from "@material-ui/core/Typography";
import EditIcon from "@material-ui/icons/Edit";
import NoDataFound from "assets/nodata-found.png";
import "./style.css";
import { Button } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  table: {
    border: "solid 1px #e0e0e0",
  },
  heading: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: "500 !important",
    paddingBottom: "1.6rem !important",
  },
}));
function CoderHistoDialog(props) {
  const classes = useStyles();
  const history = useHistory();
  const [rows, setRows] = useState([]);

  useEffect(() => {
    if (props.coderData.paHistory) {
      let response = props.coderData.paHistory.response;
      if (response.responseCode === 0) {
        setRows(response.data);
      }
    }
  }, [props.coderData.paHistory]);

  const editClick = (data) => {
    props.getCoder({
      claimId: data.claimId,
      codingId: data.medicalCodingId,
    });
    history.push("/details");
  };
  
  return (
    <Dialog
      onClose={() => props.handleClose()}
      open={props.open}
      fullWidth={true}
      maxWidth={"lg"}
    >
      <div className="pa_history_main">
        <Typography className={classes.heading}>
          Coder portal History
        </Typography>
        <TableContainer style={{ height: 600 }}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                {[
                  "DOS",
                  "Provider",
                  "Type",
                  "Payor",
                  "Owner",
                  "Status",
                  "Result",
                  "Actions",
                ].map((x, i) => (
                  <TableCell
                    key={i}
                    className={"data_table_list_cell"}
                    align={"left"}
                    sortDirection={false}
                  >
                    <TableSortLabel active={false} direction={"asc"}>
                      {x}
                    </TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((x, i) => (
                <TableRow key={i}>
                  <TableCell align="left">{x.appointmentTime}</TableCell>
                  <TableCell align="left">{x.providerName}</TableCell>
                  <TableCell align="left">{x.appointmentType}</TableCell>
                  <TableCell align="left">{x.primaryPayorName}</TableCell>
                  <TableCell align="left">{x.owner}</TableCell>
                  <TableCell align="left">{x.status}</TableCell>
                  <TableCell align="left">{x.result}</TableCell>
                  <TableCell align="left">
                    <div className="table_icon">
                      <EditIcon
                        className="table_editBtn"
                        fontSize="small"
                        onClick={() =>
                          editClick({
                            claimId: x.claimId,
                            codingId: x.medicalCodingId,
                          })
                        }
                      />
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {rows.length === 0 && (
            <div className="nodatafound">
              <img src={NoDataFound} alt={"NoDataFound"} />
            </div>
          )}
        </TableContainer>
        <div className="pa_history_btns">
          <Button
            color="primary"
            variant="outlined"
            onClick={() => props.handleClose()}
          >
            Cancel
          </Button>
        </div>
      </div>
    </Dialog>
  );
}

const mapStateToProps = (state) => ({
  coderData: state.coder,
});

const mapDispatchToProps = (dispatch) => ({
  getCoder: (values) => dispatch(getCoder(values)),
});
CoderHistoDialog.propTypes = {
  getCoder: PropTypes.func,
};
export default connect(mapStateToProps, mapDispatchToProps)(CoderHistoDialog);
