import React, { useEffect } from 'react';
import { useState, useRef } from 'react';
import { toast } from 'react-hot-toast';
import { connect } from 'react-redux';
import {
  cptSearch,
  cptDelete,
  getCoder,
  cptEdit,
} from '../../../../Redux/CoderApis/main.actions';
import DragIcon from '@material-ui/icons/DragHandle';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import EditIcon from '@material-ui/icons/Edit';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Checkbox } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import Input from '../../../../Input';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import CptDialog from '../CptDialog';
import './style.css';

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  iconColor: {
    color: '#ffff',
  },
  cellinfo: {
    fontSize: '14px !important',
    // padding: "15px !important",
  },
  cellhead: {
    fontSize: '16px !important',
    padding: '9px !important',
    paddingLeft: '2px !important',
    paddingRight: '2px !important',
    // border: '1px solid red',
    whiteSpace: 'nowrap !important',
  },
  cpt: {
    fontSize: '16px !important',
    padding: '9px !important',
    paddingLeft: '2px !important',
    paddingRight: '2px !important',
    // border: '1px solid red',
    overflowX: 'hidden !important',
    whiteSpace: 'nowrap !important',
  },
  checkBox: {
    fontSize: '16px !important',
    padding: '9px !important',
    paddingLeft: '0px !important',
    paddingRight: '0px !important',
    whiteSpace: 'nowrap !important',
  },
  checkBoxCell: {
    width: '20px',
    paddingLeft: '0px',
  },
});
//green checkbox
const GreenCheckbox = withStyles({
  root: {
    color: 'primary', // Unchecked color
    '&$checked': {
      color: 'success', // Checked color
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);


function CptTable(props) {
  const [openCpt, setopenCpt] = useState(false);
  const [cptIndex, setCptIndex] = useState(0);
  const [cptList, setCptList] = useState([]);

  const [cptArray, setCptArray] = useState([
    {
      id: 0,
      // title: '',
      cptCode: '',
      dos: props.dos,
      description: '',
      icdPointer1: 0,
      icdPointer2: 0,
      icdPointer3: 0,
      icdPointer4: 0,
      icdPointer5: 0,
      icdPointer6: 0,
      icdPointer7: 0,
      icdPointer8: 0,
      cptId: '',
      fee: '',
      modifier1: '',
      modifier2: '',
      modifier3: '',
      modifier4: '',
      unit: 1,
      amount: '',
      ndcCode: '',
      ndcMeasure: 0,
      ndcUnit: 0,
      pos: '',
      tos: '',
      comments: '',
    },
  ]);

  const classes = useRowStyles();

  const cptSearchFn = (e, i) => {
    if (e.target.value.length > 2) {
      props.cptSearch({
        cptCode: e.target.value,
        applicationId: props.applicationId,
        clinicId: props.rowInfo.clinicId,
      });
    }
  };

  useEffect(() => {
    if (props.coderData.cptSearchResponse) {
      let Response = props.coderData.cptSearchResponse.response;
      if (Response.data === null) {
        setCptList([]);
      }
      if (Response.responseCode === 0) {
        let clist = Response.data.map((x) => {
          return {
            title: x.modifier ? x.cptCode + '-' + x.modifier : x.cptCode,
            ...x,
          };
        });
        setCptList(clist);
      }
    }
  }, [props.coderData.cptSearchResponse]);

  const addNewRow = () => {
    let cpt = cptArray;
    cpt.push({
      id: 0,
      cptCode: '',
      cptId: '',
      dos: props.dos,
      description: '',
      icdPointer1: 0,
      icdPointer2: 0,
      icdPointer3: 0,
      icdPointer4: 0,
      icdPointer5: 0,
      icdPointer6: 0,
      icdPointer7: 0,
      icdPointer8: 0,
      fee: '',
      modifier1: '',
      modifier2: '',
      modifier3: '',
      modifier4: '',
      unit: 1,
      amount: '',
      ndcCode: '',
      ndcMeasure: 0,
      ndcUnit: 0,
      pos: '',
      tos: '',
      comments: '',
    });
    setCptArray([...cpt]);
  };

  const removeRow = (index) => {
    if (cptArray.length === 1) {
      if (cptArray[index].cptId) {
        props.cptDelete(cptArray[index].cptId);
      }
      setCptArray([
        {
          id: 0,
          cptCode: '',
          cptId: '',
          // title: '',
          dos: '',
          description: '',
          icdPointer1: 0,
          icdPointer2: 0,
          icdPointer3: 0,
          icdPointer4: 0,
          icdPointer5: 0,
          icdPointer6: 0,
          icdPointer7: 0,
          icdPointer8: 0,
          fee: '',
          modifier1: '',
          modifier2: '',
          modifier3: '',
          modifier4: '',
          unit: 1,
          amount: '',
          ndcCode: '',
          ndcMeasure: 0,
          ndcUnit: 0,
          pos: '',
          tos: '',
          comments: '',
        },
      ]);
    } else {
      let cpt = cptArray;
      if (cpt[index].cptId) {
        props.cptDelete(cpt[index].cptId);
      }
      cpt.splice(index, 1);
      setCptArray([...cpt]);
    }
  };
  useEffect(() => {
    if (props.coderData.cptDeleteResponse) {
      let Response = props.coderData.cptDeleteResponse.response;
      if (Response.responseCode === 0) {
        toast.success('CPT Deleted Successfully');
        props.coderData.cptDeleteResponse = null;
        // props.getCoder({
        //   apptId: data.appointmentId,
        //   codingId: data.medicalCodingId,
        // });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.coderData.cptDeleteResponse]);

  const handleChangeCpt = (i, y) => {
    if (y === null) {
      let form = cptArray;
      form[i].cptCode = '';
      form[i].modifier1 = '';
      // form[i].title = '';
      form[i].description = '';
      form[i].fee = '';
      form[i].cptId = '';
      form[i].unit = 1;
      form[i].amount = '';
      form[i].tos = '';
      form[i].pos = '';
      setCptArray([...form]);
    }
    if (y) {
      let form = cptArray;
      form[i].cptCode = y.cptCode;
      form[i].modifier1 = y.modifier;
      // form[i].title = y.cptCode;
      form[i].description = y.description;
      form[i].fee = parseFloat(y.fee).toFixed(2);
      form[i].cptId = y.cptId;
      form[i].unit = 1;
      form[i].amount = parseFloat(y.fee).toFixed(2);
      form[i].tos = y.tos;
      form[i].pos = y.pos;
      setCptArray([...form]);
    }
  };

  const cptBlur = (i) => {
    let form = cptArray;
    if (form[i].cptId === '') {
      form[i].cptCode = '';
    }
    setCptArray([...form]);
  };

  useEffect(() => {
    if (
      props.coderData.procedureInfo &&
      props.coderData.procedureInfo.response
    ) {
      let response = props.coderData?.procedureInfo?.response;
      if (response.responseCode === 0) {
        let data = response.data;
        if (data?.visitServiceDtoList?.length > 0) {
          let cparray = [];
          data.visitServiceDtoList.forEach((x) => {
            cparray.push({ ...x, title: x.cptCode });
          });
          const updatedArray = cparray.map(({ title, ...rest }) => rest);
          setCptArray(
            updatedArray.sort((a, b) => a.sequenceNum - b.sequenceNum)
          );
        }
        if (data.visitServiceDtoList?.length === 0) {
          setCptArray([
            {
              id: 0,
              // title: '',
              cptCode: '',
              description: '',
              icdPointer1: 0,
              icdPointer2: 0,
              icdPointer3: 0,
              icdPointer4: 0,
              icdPointer5: 0,
              icdPointer6: 0,
              icdPointer7: 0,
              icdPointer8: 0,
              cptId: '',
              fee: '',
              modifier1: '',
              modifier2: '',
              modifier3: '',
              modifier4: '',
              unit: 1,
              amount: '',
              ndcCode: '',
              ndcMeasure: 0,
              ndcUnit: 0,
              pos: '',
              tos: '',
              comments: '',
            },
          ]);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.coderData.procedureInfo]);

  // useEffect(() => {
  //   if (localStorage.getItem('procedureInfo')) {
  //     let data = JSON.parse(localStorage.getItem('procedureInfo'));
  //     if (data.visitServiceDtoList?.length > 0) {
  //       let cparray = [];
  //       data.visitServiceDtoList.forEach((x) => {
  //         cparray.push({ ...x, title: x.cptCode });
  //       });
  //       const updatedArray = cparray.map(({ title, ...rest }) => rest);
  //       setCptArray(updatedArray.sort((a, b) => a.sequenceNum - b.sequenceNum));
  //     }
  //     if (data.visitServiceDtoList?.length === 0) {
  //       setCptArray([
  //         {
  //           id: 0,
  //           cptCode: '',
  //           description: '',
  //           icdPointer1: 0,
  //           icdPointer2: 0,
  //           icdPointer3: 0,
  //           icdPointer4: 0,
  //           icdPointer5: 0,
  //           icdPointer6: 0,
  //           icdPointer7: 0,
  //           icdPointer8: 0,
  //           cptId: '',
  //           fee: '',
  //           modifier1: '',
  //           modifier2: '',
  //           modifier3: '',
  //           modifier4: '',
  //           unit: 1,
  //           amount: '',
  //           ndcCode: '',
  //           ndcMeasure: 0,
  //           ndcUnit: 0,
  //           pos: '',
  //           tos: '',
  //           comments: '',
  //         },
  //       ]);
  //     }
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [localStorage.getItem('procedureInfo')]);

  useEffect(() => {
    props.cptInformation(cptArray);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cptArray]);

  const updateCpt = (cpt) => {
    let arr = cptArray;
    arr[cptIndex] = {
      ...arr[cptIndex],
      cptCode: cpt.cptCode,
      modifier1: cpt.modifier,
      description: cpt.description,
      fee: cpt.fee,
      pos: cpt.pos,
      tos: cpt.tos,
      ndcCode: cpt.ndcCode,
      ndcUnit: cpt.ndcUnits,
      ndcMeasure: cpt.ndcMeasure,
      comments: cpt.comments,
      cptId: cpt.cptId,
      amount: arr[cptIndex].unit * cpt.fee,
    };
    setCptArray(arr);
  };

  const openDialog = (x, i) => {
    const currentRow = JSON.parse(sessionStorage.getItem('currentRow'));
    const clinicId = currentRow.clinicId;
    setopenCpt(true);
    setCptIndex(i);
    if (x.cptId) {
      props.cptEdit({
        cptId: x.cptId,
        clinicId: clinicId,
      });
    } else {
      props.cptEdit({
        cptId: 0,
        clinicId: 0,
      });
    }
  };

  useEffect(() => {
    if (props.coderData.cptSaveResponse) {
      let Response = props.coderData.cptSaveResponse.response;
      if (Response.responseCode === 0) {
        let data = Response.data;
        let index = cptIndex;
        let cparray = cptArray;
        cparray[index].cptCode = data.cptCode;
        cparray[index].modifier1 = data.modifier1;
        // cparray[index].title = data.cptCode;
        cparray[index].description = data.description;
        cparray[index].unit = 1;
        cparray[index].fee = parseFloat(data.fee).toFixed(2);
        cparray[index].amount = parseFloat(data.fee).toFixed(2);
        cparray[index].cptId = data.cptId;
        cparray[index].tos = data.tos;
        cparray[index].pos = data.pos;
        setCptArray([...cparray]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.coderData.cptSaveResponse]);

  const unitChange = (e, i) => {
    let cparray = cptArray;
    cparray[i].unit = e.target.value;
    cparray[i].amount = cparray[i].fee * e.target.value;
    setCptArray([...cparray]);
  };

  const feeChange = (e, i) => {
    let cparray = cptArray;
    cparray[i].fee = e.target.value;
    cparray[i].amount = cparray[i].unit * e.target.value;
    setCptArray([...cparray]);
  };

  const posChange = (e, i) => {
    let cparray = cptArray;
    cparray[i].pos = e.target.value;
    setCptArray([...cparray]);
  };

  const modifierChange = (e, i, num) => {
    if (num === 1) {
      let cparray = cptArray;
      cparray[i].modifier1 = e.target.value;
      setCptArray([...cparray]);
    }
    if (num === 2) {
      let cparray = cptArray;
      cparray[i].modifier2 = e.target.value;
      setCptArray([...cparray]);
    }
  };

  const handleCheckbox = (e, i, num) => {
    if (num === 1) {
      let cparray = cptArray;
      cparray[i].icdPointer1 = e.target.checked ? 1 : 0;
      setCptArray([...cparray]);
    }
    if (num === 2) {
      let cparray = cptArray;
      cparray[i].icdPointer2 = e.target.checked ? 1 : 0;
      setCptArray([...cparray]);
    }
    if (num === 3) {
      let cparray = cptArray;
      cparray[i].icdPointer3 = e.target.checked ? 1 : 0;
      setCptArray([...cparray]);
    }
    if (num === 4) {
      let cparray = cptArray;
      cparray[i].icdPointer4 = e.target.checked ? 1 : 0;
      setCptArray([...cparray]);
    }
    if (num === 5) {
      let cparray = cptArray;
      if (e.target.checked === true) {
        cparray[i].icdPointer5 = 1;
      } else {
        cparray[i].icdPointer5 = 0;
      }
      setCptArray([...cparray]);
    }
    if (num === 6) {
      let cparray = cptArray;
      if (e.target.checked === true) {
        cparray[i].icdPointer6 = 1;
      } else {
        cparray[i].icdPointer6 = 0;
      }
      setCptArray([...cparray]);
    }
    if (num === 7) {
      let cparray = cptArray;
      if (e.target.checked === true) {
        cparray[i].icdPointer7 = 1;
      } else {
        cparray[i].icdPointer7 = 0;
      }
      setCptArray([...cparray]);
    }
    if (num === 8) {
      let cparray = cptArray;
      if (e.target.checked === true) {
        cparray[i].icdPointer8 = 1;
      } else {
        cparray[i].icdPointer8 = 0;
      }
      setCptArray([...cparray]);
    }
  };

  const checkDuplicateCpt = (option) => {
    const cptIdArray = cptArray.map((cpt) => cpt.cptId);
    return cptIdArray.includes(option.cptId);
  };

  const dragItem = useRef();
  const dragOverItem = useRef();

  const dragStart = (e, position) => {
    dragItem.current = position;
  };
  const dragEnter = (e, position) => {
    dragOverItem.current = position;
  };
  const drop = (e) => {
    const copyCptItems = [...cptArray];
    const dragItemContent = copyCptItems[dragItem.current];
    copyCptItems.splice(dragItem.current, 1);
    copyCptItems.splice(dragOverItem.current, 0, dragItemContent);
    dragItem.current = null;
    dragOverItem.current = null;
    setCptArray(copyCptItems);
  };

  return (
    <div>
      <TableContainer
        component={Paper}
        elevation={0}
        className="cpt_table_container scrollbarY--custom"
      >
        <Table aria-label="collapsible table" size="small">
          <TableHead>
            <TableRow>
              <TableCell className={classes.cellhead} align="left"></TableCell>
              <TableCell className={classes.cpt} align="left">
                CPT *
              </TableCell>
              <TableCell className={classes.cellhead} align="left">
                No *
              </TableCell>
              <TableCell className={classes.cellhead} align="center">
                Fee *
              </TableCell>
              {/* <TableCell className={classes.cellhead} align="left">
                Amount
              </TableCell> */}
              <TableCell className={classes.cellhead} align="left">
                POS
              </TableCell>
              <TableCell className={classes.cellhead} align="left">
                M1
              </TableCell>
              <TableCell className={classes.cellhead} align="left">
                M2
              </TableCell>
              <TableCell className={classes.checkBox} align="center">
                1
              </TableCell>
              <TableCell className={classes.checkBox} align="center">
                2
              </TableCell>
              <TableCell className={classes.checkBox} align="center">
                3
              </TableCell>
              <TableCell className={classes.checkBox} align="center">
                4
              </TableCell>
              <TableCell className={classes.cellhead} align="left"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {cptArray.map((x, i) => (
              <>
                <TableRow
                  key={i}
                  className={classes.root}
                  draggable
                  onDragStart={(e) => dragStart(e, i)}
                  onDragEnter={(e) => dragEnter(e, i)}
                  onDragEnd={drop}
                >
                  <TableCell
                    className={classes.cellinfo}
                    align="left"
                    style={{ padding: '0' }}
                  >
                    <div className="drag_icon_container">
                      <DragIcon />
                    </div>
                  </TableCell>
                  <TableCell className={classes.cellinfo} align="left">
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        width: '100px',
                        overflow: 'hidden !important',
                        marginRight: '15px',
                        // border: '1px solid red',
                        // paddingBottom: "12px",
                      }}
                    >
                      <Autocomplete
                        fullWidth
                        options={cptList}
                        getOptionDisabled={(option) =>
                          checkDuplicateCpt(option)
                        }
                        getOptionLabel={(option) => option.title}
                        value={{ title: cptArray[i].cptCode }}
                        onChange={(e, y) => handleChangeCpt(i, y)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Search"
                            style={{
                              width: '109px',
                              paddingRight: '2px !important',
                            }}
                            className={classes.cptSearch}
                            onChange={(e) => {
                              let arr = cptArray;
                              arr[i].cptCode = e.target.value;
                              setCptArray(arr);
                              cptSearchFn(e, i);
                            }}
                            onBlur={() => cptBlur(i)}
                          />
                        )}
                      />
                      {x.cptId ? (
                        <EditIcon
                          color="primary"
                          fontSize="small"
                          onClick={() => openDialog(x, i)}
                          className={'cpt_add_icon'}
                        />
                      ) : (
                        <AddCircleOutlineIcon
                          color="primary"
                          fontSize="small"
                          onClick={() => openDialog(x, i)}
                          className={'cpt_add_icon'}
                        />
                      )}
                    </div>
                  </TableCell>
                  <TableCell className={classes.cellinfo} align="left">
                    <div
                      style={{
                        width: '30px',
                        paddingTop: '14px',
                     
                      }}
                    >
                      <Input
                        className="input_material_field"
                        type="number"
                        value={x.unit ? x.unit : 0}
                        onChange={(e) => unitChange(e, i)}
                      />
                    </div>
                  </TableCell>
                  <TableCell className={classes.cellinfo} align="left">
                    <div
                      style={{
                        width: '65px',
                        paddingTop: '14px',
                        // marginRight: '-7px',
                      }}
                    >
                      <Input
                        className="input_material_field"
                        type="text"
                        value={x.fee}
                        onChange={(e) => feeChange(e, i)}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                          ),
                        }}
                      />
                    </div>
                  </TableCell>
                  <TableCell className={classes.cellinfo} align="left">
                    <div style={{ width: '25px', paddingTop: '14px' }}>
                      <Input
                        className="input_material_field"
                        type="text"
                        value={x.pos}
                        onChange={(e) => posChange(e, i)}
                      />
                    </div>
                  </TableCell>
                  <TableCell className={classes.cellinfo} align="left">
                    <div style={{ width: '25px', paddingTop: '14px' }}>
                      <Input
                        value={x.modifier1}
                        onChange={(e) => modifierChange(e, i, 1)}
                        className="input_material_field"
                        type="text"
                      />
                    </div>
                  </TableCell>
                  <TableCell className={classes.cellInfo} align="left">
                    <div style={{ width: '25px', paddingTop: '14px' }}>
                      <Input
                        value={x.modifier2}
                        onChange={(e) => modifierChange(e, i, 2)}
                        className="input_material_field"
                        type="text"
                      />
                    </div>
                  </TableCell>
                  <TableCell
                    className={classes.checkBoxCell}
                    align="left"
                    style={{
                      paddingTop: '18px !important',
                      // border: '1px solid red',
                    }}
                  >
                    <GreenCheckbox
                      key={'1' + x.id}
                      color="primary"
                      className="checkBox"
                      checked={x.icdPointer1 === 1 ? true : false}
                      defaultChecked={false}
                      name={'icdPointer1' + x.id}
                      onChange={(e) => handleCheckbox(e, i, 1)}
                    />
                  </TableCell>

                  <TableCell
                    className={classes.checkBoxCell}
                    align="left"
                    style={{ paddingTop: '18px' }}
                  >
                    <GreenCheckbox
                      key={'2' + x.id}
                      className="checkBox"
                      color="primary"
                      checked={x.icdPointer2 === 1 ? true : false}
                      defaultChecked={false}
                      // value={x.icdPointer2}
                      name={'icdPointer2' + x.id}
                      onChange={(e) => handleCheckbox(e, i, 2)}
                    />
                  </TableCell>
                  <TableCell
                    className={classes.checkBoxCell}
                    align="left"
                    style={{ paddingTop: '18px' }}
                  >
                    <GreenCheckbox
                      key={'3' + x.id}
                      checked={x.icdPointer3 === 1 ? true : false}
                      defaultChecked={false}
                      className="checkBox"
                      // value={x.icdPointer3}
                      color="primary"
                      name={'icdPointer3' + x.id}
                      onChange={(e) => handleCheckbox(e, i, 3)}
                    />
                  </TableCell>
                  <TableCell
                    className={classes.checkBoxCell}
                    align="left"
                    style={{ paddingTop: '18px' }}
                  >
                    <GreenCheckbox
                      key={'4' + x.id}
                      className="checkBox"
                      checked={x.icdPointer4 === 1 ? true : false}
                      defaultChecked={false}
                      // value={x.icdPointer4}
                      color="primary"
                      name={'icdPointer4' + x.id}
                      onChange={(e) => handleCheckbox(e, i, 4)}
                    />
                  </TableCell>
                  <TableCell
                    className={classes.cellinfo}
                    style={{ paddingTop: '35px !important' }}
                  >
                    <IconButton
                      className="checkBox"
                      aria-label="expand row"
                      size="small"
                    >
                      <div onClick={() => removeRow(i)}>
                        <CancelIcon color="primary" />
                      </div>

                      {cptArray.length === i + 1 && (
                        <div onClick={() => addNewRow()}>
                          <AddCircleOutlineIcon color="primary" />
                        </div>
                      )}
                    </IconButton>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={15}>
                    <div className="cpt_description_container">
                      {x.description}
                    </div>
                  </TableCell>
                </TableRow>
              </>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <CptDialog
        rowInfo={props.rowInfo}
        updateCpt={updateCpt}
        open={openCpt}
        applicationId={props.applicationId}
        handleClose={() => setopenCpt(false)}
      />
    </div>
  );
}
const mapStateToProps = (state) => ({
  coderData: state.coder,
});

const mapDispatchToProps = (dispatch) => ({
  cptSearch: (values) => dispatch(cptSearch(values)),
  cptEdit: (values) => dispatch(cptEdit(values)),
  cptDelete: (values) => dispatch(cptDelete(values)),
  getCoder: (values) => dispatch(getCoder(values)),
});
CptTable.propTypes = {
  cptSearch: PropTypes.func,
  cptEdit: PropTypes.func,
  cptDelete: PropTypes.func,
  getCoder: PropTypes.func,
};
export default connect(mapStateToProps, mapDispatchToProps)(CptTable);
