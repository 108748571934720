import React, { useContext, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  coderList,
  getCoder,
  logList,
  bulkUpdate,
  paHistory,
} from 'Redux/CoderApis/coder.actions';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { makeStyles, styled } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import CloseIcon from '@material-ui/icons/Close';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import FaxIcon from '../../../src/assets/fax.svg';
import EditIcon from '@material-ui/icons/Edit';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { dateToyyymmdd } from 'HelperFunctions/TimeDateTimestamp';
import Dropdown from 'Components/Dropdown';
import './style.css';
import {
  Checkbox,
  Dialog,
  DialogContent,
  IconButton,
  Menu,
  MenuItem,
} from '@material-ui/core';
import NoDataFound from 'assets/nodata-found.png';
import { toast } from 'react-hot-toast';
import LogDialog from 'Components/logDialog';
import CoderHistoDialog from 'Components/CoderHistoDialog';
import { Email, Phone } from '@material-ui/icons';
import { filterContext } from 'context/contextProvider';
import { EobDialog } from '../../../../../libs/eob-dialog/src';
import CallHistory from 'Components/Callhistory';
import { getClaimPreview } from '../../Redux/CoderApis/coder.actions';
import { capitalizeWords } from './../../../../../libs/helper-functions/src/lib/capitalise';
import { dateTommddyyy } from '../../../../../libs/helper-functions/src/lib/TimeDateTimestamp';
import DobPicker from '../dobPicker';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    borderTop: 'solid 1px #e0e0e0',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  logBtnTable: {
    cursor: 'pointer',
  },
}));
const GreenCheckbox = styled(Checkbox)(({ theme }) => ({
  '& .MuiSvgIcon-root': {
    fill: `lightgray`,
  },
  '&.Mui-checked': {
    '& .MuiSvgIcon-root': {
      fill: `#139696`,
    },
  },
}));
const EnhancedTable = (props) => {
  // const history = useHistory();
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [rows, setRows] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [status, setStatus] = useState([]);
  const [result, setResult] = useState([]);
  const [owner, setOwner] = useState([]);
  const [mainCheckbox, setMainCheckbox] = useState(false);
  const [logOpen, setLogOpen] = useState(false);
  const { selectedItem, headCells, setHeadCells } = useContext(filterContext);
  const [paHistoryOpen, setPaHistoryOpen] = useState(false);
  const [logName, setlogName] = useState('');
  const [orderBy, setOrderBy] = useState('');
  const [orderByParameter, setOrderByParameter] = useState('');
  const [openEob, setOpenEob] = useState(false);
  const [openCpt, setOpenCpt] = useState(false);
  const [openNotes, setOpenNotes] = useState(false);
  const [rowData, setRowData] = useState([]);
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [previewFileUrl, setPreviewFileUrl] = useState('');
  // const [status, setStatus] = useState('')

  const [openMenus, setOpenMenus] = useState({});

  const handleClick = (event, rowIndex) => {
    setOpenMenus((prevOpenMenus) => ({
      ...prevOpenMenus,
      [rowIndex]: event.currentTarget,
    }));
  };

  const handleClose = (rowIndex) => {
    setOpenMenus((prevOpenMenus) => ({
      ...prevOpenMenus,
      [rowIndex]: null,
    }));
  };

  const statuses = [
    { name: 'Filed', value: 1 },
    { name: 'Denied', value: 2 },
    { name: 'Clarification', value: 3 },
  ];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    const cache = JSON.parse(localStorage.getItem('cache'));
    let status = [];
    let result = [];
    let owner = [];
    cache.statusList.forEach((x) => {
      status.push({
        name: x.description,
        value: parseInt(x.value),
      });
    });
    cache.userDtoList.forEach((x) => {
      owner.push({
        name: x.fullName,
        value: x.userId,
      });
    });
    setStatus(status);
    setResult(result);
    setOwner(owner);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let f = props.coderData.filterForm;
    // console.log(f);
    localStorage.setItem(
      'filterData',
      JSON.stringify({
        startDate: f.startDate
          ? dateToyyymmdd(f.startDate)
          : dateToyyymmdd(new Date()),
        // : dateToyyymmdd(new Date().setFullYear(new Date().getFullYear() - 1)),
        endDate: f.endDate
          ? dateToyyymmdd(f.endDate)
          : dateToyyymmdd(new Date()),
      })
    );
    props.coderList({
      startDate: f.startDate
        ? dateToyyymmdd(f.startDate)
        : dateToyyymmdd(new Date()),
      // : dateToyyymmdd(new Date().setFullYear(new Date().getFullYear() - 1)),
      endDate: f.endDate ? dateToyyymmdd(f.endDate) : dateToyyymmdd(new Date()),
      dueDate: f.dueDate ? dateToyyymmdd(f.dueDate) : '',
      start: page,
      limit: rowsPerPage,
      ownerId: f.owner ? f.owner : '',
      providerId: f.provider ? f.provider : '',
      resultId: f.result ? f.result : '',
      statusId: f.status ? f.status : '',
      clinicId: f.clinic ? f.clinic : '',
      orderBy: orderBy,
      orderByParameter: orderByParameter,
      payorId: f.payor ? f.payor : '',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    page,
    rowsPerPage,
    props.coderData.filterForm,
    // props.coderData.bulkUpdateResponse,
    props.AppointmentData.appointmentResponse,
    orderBy,
    orderByParameter,
    props.backButtonClicked,
  ]);

  useEffect(() => {
    if (props.coderData.bulkUpdateResponse) {
      let response = props.coderData.bulkUpdateResponse.response;
      if (response.responseCode === 0) {
        toast.success('Updation Success');
        props.coderData.bulkUpdateResponse = null;
        setMainCheckbox(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.coderData.bulkUpdateResponse]);

  useEffect(() => {
    if (props.coderData.tableData) {
      let response = props.coderData.tableData.response;
      if (response.responseCode === 0) {
        setRows(response.data.results);
        localStorage.setItem('allItems', JSON.stringify(response.data.results));
        setTotalCount(response.data.totalRecords);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.coderData.tableData]);

  const editClick = (data) => {
    props.passRowData(data);
    const list = JSON.parse(localStorage.getItem('allItems'));
    list.forEach((item) => {
      if (parseInt(item.claimId) === parseInt(data.claimId)) {
        sessionStorage.setItem('currentSelection', JSON.stringify(item));
        sessionStorage.setItem('currentRow', JSON.stringify(item));
        // sessionStorage.setItem('clinicId', JSON.stringify(item.clinicId));
      }
    });
  };

  // const handleLog = (x) => {
  //   props.logList({
  //     claimId: x.claimId,
  //   });
  //   setlogName(x.patientLName + ',' + x.patientFName + ' ' + x.patientMName);
  //   setLogOpen(true);
  // };

  const handleLogClose = () => {
    setLogOpen(false);
  };

  const handleCloseEob = () => {
    setOpenEob(false);
  };
  const handleClosNotes = () => {
    setOpenNotes(false);
  };
  const handleClosCpt = () => {
    setOpenCpt(false);
  };

  const handleCheckboxArray = (e) => {
    setMainCheckbox(e.target.checked);
    let r = [];
    rows.forEach((x) => {
      r.push({
        ...x,
        isSelected: e.target.checked,
      });
    });
    setRows([...r]);
    props.checkBoxArray([...r]);
  };

  const handleCheckbox = (e, i) => {
    e.stopPropagation();
    let r = rows;
    r[i] = { ...r[i], isSelected: e.target.checked };
    setRows([...r]);
    props.checkBoxArray([...r]);
  };

  const updateOwner = (e, x, i) => {
    let r = rows;
    r[i].ownerId = e.target.value;
    setRows([...r]);
    props.bulkUpdate([
      {
        claimId: x.claimId,
        owner: owner.find((x) => x.value === e.target.value)
          ? owner.find((x) => x.value === e.target.value)['name']
          : '',
        ownerId: e.target.value,
      },
    ]);
  };

  function trimStringWithEllipsis(str, maxLength) {
    if (str.length > maxLength) {
      return str.slice(0, maxLength) + '...';
    } else {
      return str;
    }
  }

  function formatDateToShortYear(inputDate) {
    if (inputDate !== undefined) {
      const [day, month, year] = inputDate.split('/');
      const shortYear = year.slice(-2);
      return `${day}/${month}/${shortYear}`;
    }
  }

  const handleSort = (index) => {
    let ar = headCells;
    ar.forEach((y, i) => {
      if (i === index) {
        ar[i].active = true;
        ar[i].direction = ar[i].direction === 'asc' ? 'desc' : 'asc';
        setOrderByParameter(ar[i].id);
        setOrderBy(ar[i].direction === 'asc' ? 'desc' : 'asc');
      } else {
        ar[i].active = false;
        ar[i].direction = 'desc';
      }
    });
    setHeadCells([...ar]);
  };

  // const handleCoderHistory = (x) => {
  //   props.paHistory(x.patientId);
  //   setPaHistoryOpen(true);
  // };

  const handlePreview = (x, type) => {
    // console.log(x);
    // console.log(type);
    if (type === 'primary') {
      props.getClaimPreview({
        claimId: x.claimId,
        patientId: x.patientId,
        rankType: 1,
      });
    } else {
      props.getClaimPreview({
        claimId: x.claimId,
        patientId: x.patientId,
        rankType: 2,
      });
    }
  };

  useEffect(() => {
    if (props.coderData.claimPreview) {
      const previewFile = props.coderData.claimPreview.response;
      const blob = new Blob([previewFile], { type: 'application/pdf' });
      const previewUrl = URL.createObjectURL(blob);
      setIsPreviewOpen(true);
      setPreviewFileUrl(previewUrl);
      props.coderData.claimPreview = null;
    }
  }, [props.coderData.claimPreview]);

  function addDays(date, days) {
    date.setDate(date.getDate() + days);
    return date;
  }

  const defaultDueDate = addDays(new Date(), 2);

  const sentenceCase = (txt) => {
    if (!txt) return '';
    return txt
      .toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  const updateDueDate = (e, x, i) => {
    const row = rows;
    row[i].dueDate = e.target.value;
    setRows([...row]);
    props.bulkUpdate([
      {
        claimId: x.claimId,
        dueDate: dateToyyymmdd(e.target.value),
      },
    ]);
  };

  return (
    <div className={classes.root}>
      <LogDialog
        handleClose={() => handleLogClose()}
        open={logOpen}
        name={logName}
      />
      <CoderHistoDialog
        open={paHistoryOpen}
        handleClose={() => setPaHistoryOpen(false)}
      />
      {/* <AddCircleRoundedIcon
        color="primary"
        className="appointment_add_icon"
        onClick={() => props.priorDialog(true)}
      /> */}
      <TableContainer className="coder_table_list">
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          aria-label="enhanced table"
        >
          <TableHead className="dataTable_head">
            <TableRow className="dataTable_head_row">
              <TableCell
                className={'data_table_list_cell'}
                align={'left'}
                sortDirection={false}
              >
                <TableSortLabel
                  active={false}
                  direction={'asc'}
                  className="table_checkbox"
                >
                  <GreenCheckbox
                    size="small"
                    onChange={handleCheckboxArray}
                    checked={mainCheckbox}
                  ></GreenCheckbox>
                </TableSortLabel>
              </TableCell>
              {/* {headCells&&headCells.map((x, i) => (
                <TableCell
                  key={i}
                  className={"data_table_list_cell"}
                  align={"left"}
                  sortDirection={false}
                >
                  <TableSortLabel
                    onClick={(e) => handleSort(i)}
                    active={x.active}
                    direction={x.direction}
                  >
                    {x.label}
                  </TableSortLabel>
                </TableCell>
              ))} */}
              {headCells &&
                headCells
                  .filter((x) => x.status === true)
                  .map((x, i) => (
                    <TableCell
                      key={i}
                      className={'data_table_list_cell'}
                      align={'left'}
                      sortDirection={false}
                    >
                      <TableSortLabel
                        onClick={(e) => handleSort(i)}
                        active={x.active}
                        direction={x.direction}
                      >
                        {x.label}
                      </TableSortLabel>
                    </TableCell>
                  ))}
              <TableCell align="center" className="data_table_list_cell">
                <TableSortLabel>Billed</TableSortLabel>
              </TableCell>
              <TableCell align="center" className="data_table_list_cell">
                <TableSortLabel>Paid</TableSortLabel>
              </TableCell>
              <TableCell align="center" className="data_table_list_cell">
                <TableSortLabel>Balance</TableSortLabel>
              </TableCell>
              <TableCell align="center" className="data_table_list_cell">
                <TableSortLabel>Actions</TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((x, i) => (
              <TableRow
                className="tableRow"
                // onClick={() => editClick(x)}
                key={i}
                hover
                role="checkbox"
              >
                <TableCell className={'data_table_list_cell'} scope="row">
                  <div className="table_checkbox">
                    <GreenCheckbox
                      size="small"
                      checked={x.isSelected === true ? true : false}
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                      onChange={(e) => handleCheckbox(e, i)}
                    ></GreenCheckbox>
                  </div>
                </TableCell>

                {selectedItem.dos && (
                  <TableCell className={'data_table_list_cell'} scope="row">
                    {formatDateToShortYear(x.dos)}
                  </TableCell>
                )}
                {selectedItem.patientName && (
                  <TableCell className={'data_table_list_cell'} align="left">
                    {/* {sentenceCase(x.patientLName)}
                    {sentenceCase(x.patientFName) &&
                      ', ' + sentenceCase(x.patientFName)}
                    {sentenceCase(x.patientMName) &&
                      ' ' + sentenceCase(x.patientMName)} */}
                    {trimStringWithEllipsis(
                      (
                        (x.patientLName ? x.patientLName + ',' : '') +
                        (x.patientFName ? ' ' + x.patientFName : '') +
                        (x.patientMName ? ' ' + x.patientMName : '')
                      ).trim(),
                      15
                    )}
                  </TableCell>
                )}
                {selectedItem.patientDob && (
                  <TableCell className={'data_table_list_cell'} align="left">
                    {formatDateToShortYear(x.patientDob)}
                  </TableCell>
                )}
                {selectedItem.clinicName && (
                  <TableCell
                    className={'data_table_list_cell clinic_name_table_row'}
                    align="left"
                  >
                    {trimStringWithEllipsis(x.clinicName, 15)}
                  </TableCell>
                )}
                {selectedItem.providerName && (
                  <TableCell className={'data_table_list_cell'} align="left">
                    {trimStringWithEllipsis(
                      (
                        (x.providerLName ? x.providerLName + ',' : '') +
                        (x.providerFName ? ' ' + x.providerFName : '') +
                        (x.providerMName ? ' ' + x.providerMName : '')
                      ).trim(),
                      11
                    )}
                  </TableCell>
                )}
                {selectedItem.appointmentType && (
                  <TableCell
                    className={'data_table_list_cell appt_type'}
                    align="left"
                  >
                    {x.appointmentType}
                  </TableCell>
                )}
                {selectedItem.payor && (
                  <TableCell
                    className={'data_table_list_cell payor_name'}
                    align="left"
                  >
                    {capitalizeWords(trimStringWithEllipsis(x.payorName, 10))}
                  </TableCell>
                )}
                {selectedItem.dueDate && (
                  <TableCell
                    className={'data_table_list_cell payor_name'}
                    align="left"
                  >
                    {/* {x.dueDate !== ''
                      ? x.dueDate
                      : dateTommddyyy(defaultDueDate)} */}
                    <DobPicker
                      inputVariant={'standard'}
                      value={x.dueDate}
                      onChange={(e) => updateDueDate(e, x, i)}
                    />
                  </TableCell>
                )}
                {selectedItem.owner && (
                  <TableCell className={'data_table_dropdown'} align="left">
                    <div style={{ marginTop: '-10px' }}>
                      <Dropdown
                        onClick={(e) => e.stopPropagation()}
                        options={[{ name: 'Unassigned', value: '0' }, ...owner]}
                        size="small"
                        value={x.ownerId}
                        onChange={(e) => updateOwner(e, x, i)}
                      />
                    </div>
                  </TableCell>
                )}
                {selectedItem.status && (
                  <TableCell className={'data_table_list_cell'} align="left">
                    {/* <Dropdown
                      options={[{ name: 'No Status', value: '0' }, ...status]}
                      size="small"
                      onClick={(e) => e.stopPropagation()}
                      value={x.statusId}
                      onChange={(e) => updateStatus(e, x, i)}
                    /> */}
                    {capitalizeWords(x.status)}
                  </TableCell>
                )}
                <TableCell className="data_table_list_cell">
                  ${Math.round((x.billed + Number.EPSILON) * 100) / 100}
                </TableCell>
                <TableCell className="data_table_list_cell">
                  ${Math.round((x.paid + Number.EPSILON) * 100) / 100}
                </TableCell>
                <TableCell className="data_table_list_cell">
                  ${Math.round((x.balance + Number.EPSILON) * 100) / 100}
                </TableCell>

                <TableCell
                  className={'data_table_list_cell iconCell'}
                  align="center"
                >
                  <div className="table_icon">
                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                        setRowData(x);
                        setOpenNotes(!openNotes);
                      }}
                      className="underLineButton"
                    >
                      Notes
                    </div>
                    {x.statusId !== 1 ? (
                      <div
                        className="underLineButton"
                        onClick={(e) => handleClick(e, i)}
                      >
                        Preview
                      </div>
                    ) : (
                      <div className="greyButton">Preview</div>
                    )}
                    <Menu
                      anchorEl={openMenus[i]}
                      open={Boolean(openMenus[i])}
                      onClose={() => handleClose(i)}
                    >
                      {x.payerId > 0 ? (
                        <MenuItem onClick={() => handlePreview(x, 'primary')}>
                          Primary
                        </MenuItem>
                      ) : (
                        <MenuItem disabled>
                          <span style={{ color: 'grey' }}>Primary</span>
                        </MenuItem>
                      )}
                      {x.secondaryPayerId > 0 ? (
                        <MenuItem onClick={() => handlePreview(x, 'seco')}>
                          Secondary
                        </MenuItem>
                      ) : (
                        <MenuItem disabled>
                          <span style={{ color: 'grey' }}>Secondary</span>
                        </MenuItem>
                      )}
                    </Menu>
                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                        setRowData(x);
                        setOpenEob(!openEob);
                      }}
                      className="underLineButton"
                    >
                      Eob
                    </div>
                    <div className="iconButtons">
                      <Link to="/edit">
                        <IconButton
                          className="table_editBtn"
                          fontSize="small"
                          onClick={() => editClick(x)}
                        >
                          <EditIcon color="primary" />
                        </IconButton>
                      </Link>

                      {/* <IconButton
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        area-label="email"
                        color="primary"
                      >
                        <Email color="primary" />
                      </IconButton> */}
                      {/* <IconButton>
                        <img
                          className="faxIcon"
                          style={{ width: '27px' }}
                          src={FaxIcon}
                          alt="Fax"
                        />
                      </IconButton> */}
                      {/* <IconButton area-label="email" color="primary">
                        <Phone color="primary" />
                      </IconButton> */}
                    </div>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Dialog
          className="scrollbarY--custom"
          maxWidth="md"
          //fullScreen
          fullWidth
          open={isPreviewOpen}
        >
          <DialogContent className="previewDialogContent">
            <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
              <div className="preview_closeBtn">
                <CloseIcon onClick={() => setIsPreviewOpen(false)} />
              </div>
            </div>
            <div className="preview">
              <object
                menu="false"
                width="100%"
                height="83%"
                data={previewFileUrl}
                type="application/pdf"
              ></object>
            </div>
          </DialogContent>
        </Dialog>
        <Dialog
          maxWidth="lg"
          open={openEob}
          onClose={() => handleCloseEob()}
          className="notes_dialogueContent scrollbarY--custom"
        >
          <DialogContent>
            <EobDialog
              applicationId={8}
              claimId={rowData.claimId}
              handleEobClose={() => handleCloseEob()}
            />
          </DialogContent>
        </Dialog>

        {/* Notes Dialog */}
        <Dialog
          maxWidth="lg"
          fullWidth={true}
          open={openNotes}
          onClose={() => handleClosNotes()}
          className="notes_dialogueContent scrollbarY--custom"
        >
          <DialogContent>
            <CallHistory
              onClose={() => handleClosNotes()}
              applicationId={7}
              rowInfo={rowData}
            />
          </DialogContent>
        </Dialog>
        <Dialog
          maxWidth="lg"
          open={openCpt}
          onClose={() => handleClosCpt()}
          className="notes_dialogueContent scrollbarY--custom"
        >
          <DialogContent>{/* <CptSearch /> */}</DialogContent>
        </Dialog>
        {rows.length === 0 && (
          <div className="nodatafound">
            <img src={NoDataFound} alt={'NoDataFound'} />
          </div>
        )}
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[10, 12, 20, 25, 50, 100]}
        component="div"
        count={totalCount}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  coderData: state.coder,
  AppointmentData: state.Appointment,
});

const mapDispatchToProps = (dispatch) => ({
  coderList: (values) => dispatch(coderList(values)),
  getCoder: (values) => dispatch(getCoder(values)),
  logList: (values) => dispatch(logList(values)),
  bulkUpdate: (values) => dispatch(bulkUpdate(values)),
  paHistory: (values) => dispatch(paHistory(values)),
  getClaimPreview: (values) => dispatch(getClaimPreview(values)),
});
EnhancedTable.propTypes = {
  coderList: PropTypes.func,
  getCoder: PropTypes.func,
  logList: PropTypes.func,
  bulkUpdate: PropTypes.func,
  paHistory: PropTypes.func,
  getClaimPreview: PropTypes.func,
};
export default connect(mapStateToProps, mapDispatchToProps)(EnhancedTable);
