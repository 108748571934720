export const capitalizeWords = (inputString) => {
  const words = inputString.split(' ');
  let resultString =
    words.length > 0
      ? words[0].charAt(0).toUpperCase() + words[0].slice(1).toLowerCase()
      : '';
  for (let i = 1; i < words.length; i++) {
    resultString +=
      ' ' + words[i].charAt(0).toUpperCase() + words[i].slice(1).toLowerCase();
  }
  return resultString;
};
