import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { cptSave } from '../../../../Redux/CoderApis/main.actions';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { Grid } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import { Dropdown } from '../../../../drop-down/src/index';
import { useState } from 'react';
import { toast } from 'react-hot-toast';

function CptDialog(props) {
  const [pos, setPos] = useState([]);
  const [tos, setTos] = useState([]);
  const [form, setForm] = useState({
    cashFee: 0,
    clinicId: '',
    comments: '',
    cptCode: '',
    cptId: 0,
    description: '',
    fee: 0,
    frequencyType: 1,
    modifier: '',
    ndcCode: 0,
    ndcMeasure: 0,
    ndcUnits: 1,
    pos: '',
    tos: '',
    unit: 1,
  });

  const handleSubmit = (e) => {
    e.stopPropagation();
    e.preventDefault();
    props.cptSave({
      data: { ...form, clinicId: props.rowInfo.clinicId },
      applicationId: props.applicationId,
    });
  };

  useEffect(() => {
    if (props.coderData.cptSaveResponse) {
      let Response = props.coderData.cptSaveResponse.response;
      if (Response.responseCode === 0) {
        props.handleClose();
        props.updateCpt(Response.data);
        toast.success('CPT saved Successfully');
        props.coderData.cptSaveResponse = null;
      }
      if (Response.responseCode === 136) {
        toast.error('CPT already exists');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.coderData.cptSaveResponse]);

  useEffect(() => {
    const cache = JSON.parse(localStorage.getItem('cache'));
    let pos = [];
    let tos = [];
    cache.posList.forEach((x) => {
      pos.push({
        name: x.description,
        value: x.value,
      });
    });

    cache.tosList.forEach((x) => {
      tos.push({
        name: x.description,
        value: x.value,
      });
    });
    setPos(pos);
    setTos(tos);
  }, []);

  useEffect(() => {
    if (props.coderData.cptEditResponse) {
      let response = props.coderData.cptEditResponse.response;
      if (response.responseCode === 0) {
        let data = props.coderData.cptEditResponse.response.data;
        if (data) {
          setForm({
            cashFee: data.cashFee,
            clinicId: data.clinicId,
            comments: data.comments,
            cptCode: data.cptCode,
            cptId: data.cptId,
            description: data.description,
            fee: data.fee,
            frequencyType: data.frequencyType,
            modifier: data.modifier,
            ndcCode: data.ndcCode,
            ndcMeasure: data.ndcMeasure,
            ndcUnits: data.ndcUnits,
            pos: data.pos,
            tos: data.tos,
            unit: data.unit,
          });
        }
      } else {
        setForm({
          cashFee: 0,
          clinicId: '',
          comments: '',
          cptCode: '',
          cptId: 0,
          description: '',
          fee: 0,
          frequencyType: 1,
          modifier: '',
          ndcCode: 0,
          ndcMeasure: 0,
          ndcUnits: 1,
          pos: '',
          tos: '',
          unit: 1,
        });
      }
    }
  }, [props.coderData.cptEditResponse]);

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={() => props.handleClose()}
        aria-labelledby="form-dialog-title"
      >
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <div style={{ padding: '1rem' }}>
              <p
                style={{
                  paddingBottom: '20px',
                  fontSize: '20px',
                  fontWeight: '500',
                }}
              >
                Add/Edit CPT
              </p>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    required
                    type="text"
                    label="Code"
                    margin="normal"
                    variant="outlined"
                    onChange={(e) =>
                      setForm({ ...form, cptCode: e.target.value })
                    }
                    value={form.cptCode ? form.cptCode : ''}
                  />
                </Grid>
                {/* <Grid item xs={8}></Grid> */}
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    required
                    type="text"
                    label="Description"
                    margin="normal"
                    variant="outlined"
                    onChange={(e) =>
                      setForm({ ...form, description: e.target.value })
                    }
                    value={form.description ? form.description : ''}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    type="text"
                    label="Modifier"
                    margin="normal"
                    variant="outlined"
                    inputProps={{ maxLength: 2 }}
                    onChange={(e) =>
                      setForm({ ...form, modifier: e.target.value })
                    }
                    value={form.modifier ? form.modifier : ''}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    type="text"
                    label="NDC Code"
                    margin="normal"
                    variant="outlined"
                    onChange={(e) =>
                      setForm({ ...form, ndcCode: e.target.value })
                    }
                    value={form.ndcCode ? form.ndcCode : ''}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Fee"
                    type="number"
                    onChange={(e) => setForm({ ...form, fee: e.target.value })}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                    variant="outlined"
                    value={form.fee ? form.fee : ''}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    type="number"
                    label="NDC units"
                    margin="normal"
                    variant="outlined"
                    value={form.ndcUnits ? form.ndcUnits : ''}
                    onChange={(e) =>
                      setForm({ ...form, ndcUnits: parseInt(e.target.value) })
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    type="number"
                    onChange={(e) =>
                      setForm({ ...form, cashFee: e.target.value })
                    }
                    label="Cash Fee"
                    value={form.cashFee ? form.cashFee : ''}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    type="number"
                    label="NDC measure"
                    margin="normal"
                    variant="outlined"
                    onChange={(e) =>
                      setForm({
                        ...form,
                        ndcMeasure: parseInt(e.target.value),
                      })
                    }
                    value={form.ndcMeasure ? form.ndcMeasure : ''}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Dropdown
                    tabIndex={3}
                    options={pos}
                    label="POS"
                    variant="outlined"
                    onChange={(e) => setForm({ ...form, pos: e.target.value })}
                    value={form.pos ? form.pos : ''}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Dropdown
                    tabIndex={3}
                    options={tos}
                    label="TOS"
                    variant="outlined"
                    onChange={(e) => setForm({ ...form, tos: e.target.value })}
                    value={form.tos ? form.tos : ''}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    type="text"
                    label="Comments"
                    margin="normal"
                    variant="outlined"
                    onChange={(e) =>
                      setForm({ ...form, comments: e.target.value })
                    }
                    value={form.comments ? form.comments : ''}
                  />
                </Grid>
              </Grid>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row-reverse',
                  gap: '10px',
                }}
              >
                <Button
                  // onClick={() => props.handleClose()}
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  Save
                </Button>
                <Button
                  onClick={() => props.handleClose()}
                  variant="outlined"
                  color="primary"
                >
                  Cancel
                </Button>
              </div>
            </div>
          </DialogContent>
        </form>
      </Dialog>
    </div>
  );
}
const mapStateToProps = (state) => ({
  coderData: state.coder,
});

const mapDispatchToProps = (dispatch) => ({
  cptSave: (values) => dispatch(cptSave(values)),
});
CptDialog.propTypes = {
  cptSave: PropTypes.func,
};
export default connect(mapStateToProps, mapDispatchToProps)(CptDialog);
