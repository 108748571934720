import React, { useEffect } from 'react';
import './style.css';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
// import FileUploaderDropzone from 'Components/FileUploaderDropzone/FileUploaderDropzone';
import { FileUploaderDropzone } from '../../../../../libs/file-uploader-drop-zone/src/index';
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: '500 !important',
  },
}));

function UploadInformation(props) {
  const classes = useStyles();
  useEffect(() => {
    console.log(props.rowData);
  }, []);

  return (
    <div className="upload_main_div">
      {/* <Typography className={classes.heading}>Upload Files</Typography> */}
      <FileUploaderDropzone
        rowInfo={props.rowData}
        applicationId={props.applicationId}
      />
    </div>
  );
}

export default UploadInformation;
