import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { logInfoSave, getCoder } from 'Redux/CoderApis/coder.actions';
import PropTypes from 'prop-types';
import { Button, Grid } from '@material-ui/core';
import Input from 'Components/Input';
import DobPicker from 'Components/dobPicker';
import { makeStyles } from '@material-ui/core/styles';
import Dropdown from 'Components/Dropdown';
import Typography from '@material-ui/core/Typography';
import { dateToyyymmdd } from 'HelperFunctions/TimeDateTimestamp';
import { useState } from 'react';
import { toast } from 'react-hot-toast';
import './style.css';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: '500 !important',
    paddingBottom: '1.6rem !important',
  },
}));

function CallInfo(props) {
  // const [status, setStatus] = useState([]);
  const [result, setResult] = useState([]);
  const [owner, setOwner] = useState([]);
  const [form, setForm] = useState({
    // activityDate: new Date(),
    // // activityId: 0,
    // claimId: 0,
    // // visitId: 0,
    // applicationId: 8,
    // callRefNo: '',
    // // coInsurance: '',
    // // copay: '',
    // csrName: '',
    // dueDate: '',
    // duration: 0,
    // // eligibilityStatus: 0,
    // // insuranceId: 0,
    // // insuranceType: 0,

    // // medicalCodingId: 0,
    // // outstandingDeductible: 0,
    // // patientBalance: 0,
    // // patientId: 0,
    // payorName: '',
    // payorPortalId: 0,
    // phoneNo: '',
    // priorAuthId: 0,
    // priorAuthNo: '',
    // result: 0,
    // staffName: '',
    // staffUserId: parseInt(localStorage.getItem('ownerId')),
    // staffProductivityCallerId: 0,
    // staffProductivityOtherId: 0,
    // status: 0,
    // typeId: 0,
    // notes: '',
    activityDate: new Date(),
    activityId: 0,
    applicationId: 8,
    appointmentId: 0,
    callRefNo: '',
    claimId: 0,
    coInsurance: '',
    copay: '',
    csrName: '',
    dueDate: '',
    duration: 0,
    insuranceId: 0,
    insuranceType: 0,
    medicalCodingId: 0,
    notes: '',
    outstandingDeductible: 0,
    patientBalance: 0,
    patientId: 0,
    payorName: '',
    payorPortalId: 0,
    phoneNo: '',
    priorAuthId: 0,
    priorAuthNo: '',
    result: 0,
    staffName: '',
    staffProductivityCallerId: 0,
    staffProductivityOtherId: 0,
    staffUserId: parseInt(localStorage.getItem('ownerId')),
    status: 0,
    taskId: 0,
    typeId: 0,
    visitId: 0,
  });
  const classes = useStyles();

  const handleSubmit = (e) => {
    e.preventDefault();
    let currentInfo = JSON.parse(sessionStorage.getItem('currentRow'));
    // console.log(form);
    props.logInfoSave({
      ...form,
      claimId: currentInfo.claimId,
      staffName: owner.find((x) => x.value === form.staffUserId)
        ? owner.find((x) => x.value === form.staffUserId).name
        : '',
      activityDate: dateToyyymmdd(form.activityDate),
      dueDate: dateToyyymmdd(form.dueDate),
      applicationId: 8,
    });
  };

  useEffect(() => {
    const cache = JSON.parse(localStorage.getItem('cache'));
    // let status = [];
    let result = [];
    let owner = [];
    // cache.statusList.forEach((x) => {
    //   status.push({
    //     name: x.description,
    //     value: x.value,
    //   });
    // });
    cache.userDtoList.forEach((x) => {
      owner.push({
        name: x.fullName,
        value: x.userId,
      });
    });
    // setStatus(status);
    setResult(result);
    setOwner(owner);
  }, []);

  useEffect(() => {
    if (props.coderData.logInfoResponse) {
      let Response = props.coderData.logInfoResponse.response;
      if (Response.responseCode === 0) {
        toast.success('Log Information Added');
        props.coderData.logInfoResponse = null;
        setForm({
          ...form,
          // claimId: data.claimId,
          // insuranceId: data.insuranceId,
          // medicalCodingId: data.medicalCodingId,
          // patientId: data.patient.id,
          //  staffName: data.owner,
          // result: data.result,
          // status: data.status,
          //  staffUserId: data.ownerId
          dueDate: '',
          notes: '',
        });
        // let data = JSON.parse(sessionStorage.getItem('currentRow'));
        // props.getCoder({
        //   claimId: data.claimId,
        //   codingId: data.medicalCodingId,
        // });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.coderData.logInfoResponse]);

  useEffect(() => {
    if (props.coderData.coderInfo) {
      let response = props.coderData.coderInfo.response;
      if (response.responseCode === 0) {
        let data = response.data;
        setForm({
          ...form,
          claimId: data.claimId,
          insuranceId: data.insuranceId,
          medicalCodingId: data.medicalCodingId,
          patientId: data.patient.id,
          staffName: data.owner,
          result: data.result,
          // status: data.status,
          staffUserId: data.ownerId,
          notes: '',
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.coderData.coderInfo]);

  useEffect(() => {
    // console.log('call', props.rowInfo);
    if (props.rowInfo) {
      const data = props.rowInfo;
      setForm({
        ...form,
        // claimId: data.claimId,
        // insuranceId: data.insuranceId,
        // medicalCodingId: data.medicalCodingId,
        // patientId: data.patient.id,
        staffName: data.owner,
        // result: data.result,
        // status: data.status,
        staffUserId: data.ownerId,
        notes: '',
      });
    }
  }, [props.rowInfo]);

  // useEffect(() => {
  //   if (sessionStorage.getItem('currentRow')) {
  //     let data = JSON.parse(sessionStorage.getItem('currentRow'));
  //     if (data) {
  //       console.log('call', data);
  // setForm({
  //   ...form,
  //   claimId: data.claimId,
  //   insuranceId: data.insuranceId,
  //   medicalCodingId: data.medicalCodingId,
  //   patientId: data.patient.id,
  //   staffName: data.owner,
  //   result: data.result,
  //   status: data.status,
  //   staffUserId: data.ownerId,
  //   notes: "",
  // });
  //     }
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [sessionStorage.getItem('currentRow')]);

  return (
    <div className={'callinfo_main_div'}>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography className={classes.heading}>Log</Typography>
          </Grid>
          <Grid item xs={8}>
            <div className="callinfo_button">
              <Button variant="contained" color="primary" type="submit">
                Save
              </Button>
              <Button variant="outlined" color="primary">
                Cancel
              </Button>
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={3}>
            <DobPicker
              required
              label="Date"
              value={form.activityDate}
              onChange={(e) =>
                setForm({ ...form, activityDate: e.target.value })
              }
            />
          </Grid>
          {/* <Grid item xs={3}>
            <Dropdown
              required
              variant="outlined"
              options={status}
              label="Status"
              value={form.status}
              disabled
              // onChange={(e) => setForm({ ...form, status: e.target.value })}
            />
          </Grid> */}
          {/* <Grid item xs={3}>
            <Dropdown
              required
              variant="outlined"
              options={result}
              label="Result"
              value={form.result}
              onChange={(e) => setForm({ ...form, result: e.target.value })}
            />
          </Grid> */}
          <Grid item xs={3}>
            <DobPicker
              required
              minDate={new Date()}
              label="Due Date"
              value={form.dueDate}
              onChange={(e) => setForm({ ...form, dueDate: e.target.value })}
            />
          </Grid>
          <Grid item xs={3}>
            <Dropdown variant="outlined" label="Actions" />
          </Grid>
          <Grid item xs={3}>
            <Dropdown
              required
              variant="outlined"
              options={owner}
              label="Owner"
              value={form.staffUserId}
              onChange={(e) =>
                setForm({ ...form, staffUserId: e.target.value })
              }
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              label="Notes"
              multiline
              maxRows={4}
              variant="outlined"
              value={form.notes}
              onChange={(e) => setForm({ ...form, notes: e.target.value })}
            ></Input>
          </Grid>
        </Grid>
        {/* <div className="callinfo_button">
          <Button variant="contained" color="primary" type="submit">
            Save
          </Button>
          <Button variant="outlined" color="primary">
            Cancel
          </Button>
        </div> */}
      </form>
    </div>
  );
}

const mapStateToProps = (state) => ({
  coderData: state.coder,
});

const mapDispatchToProps = (dispatch) => ({
  logInfoSave: (values) => dispatch(logInfoSave(values)),
  getCoder: (values) => dispatch(getCoder(values)),
});
CallInfo.propTypes = {
  logInfoSave: PropTypes.func,
  getCoder: PropTypes.func,
};
export default connect(mapStateToProps, mapDispatchToProps)(CallInfo);
