import { Grid } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React from 'react';

function DiagnosisSkeleton() {
  return (
    <div className="diagnosis_main_div">
      <p className="diagnosis_heading">Diagnosis</p>
      <div>
        <Grid container spacing={2} className="diagnosis_grid">
          <Grid item xs={3}>
            <Skeleton height={'50px'} variant="rect" />
          </Grid>
          <Grid item xs={3}>
            <Skeleton height={'50px'} variant="rect" />
          </Grid>
          <Grid item xs={3}>
            <Skeleton height={'50px'} variant="rect" />
          </Grid>
          <Grid item xs={3}>
            <Skeleton height={'50px'} variant="rect" />
          </Grid>
        </Grid>
      </div>
      {/* Next Block*/}
      <div>
        <div hidden={false}>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <Skeleton height={'50px'} variant="rect" />
            </Grid>
            <Grid item xs={3}>
              <Skeleton height={'50px'} variant="rect" />
            </Grid>
            <Grid item xs={3}>
              <Skeleton height={'50px'} variant="rect" />
            </Grid>
            <Grid item xs={3}>
              <Skeleton height={'50px'} variant="rect" />
            </Grid>
          </Grid>
        </div>
        <Grid item xs={12}>
          {/* <div
            style={{
              display: 'flex',
              flexDirection: 'row-reverse',
              paddingBottom: '10px',
            }}
          >
            <Button
              color="primary"
              variant="outlined"
              startIcon={
                more ? (
                  <RemoveIcon color={'primary'} />
                ) : (
                  <AddCircleOutlineIcon color={'primary'} />
                )
              }
              onClick={() => setMore(!more)}
            >
              {more ? 'Less' : 'Add'}
            </Button>
          </div> */}
        </Grid>
      </div>
      {/* <IcdDialog
        open={openIcd.status}
        handleClose={() => setopenIcd({ status: false })}
      />
      <IcdKeywordDialog
        open={openIcdkeyword.status}
        handleClose={() => setopenIcdleyword(false)}
        keywordList={handleKeywordValues}
      /> */}
    </div>
  );
}

export default DiagnosisSkeleton;
