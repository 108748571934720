import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  claimDownload,
  claimDownloadRes,
  getClaimList,
  getCoder,
} from '../../../Redux/CoderApis/main.actions';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import './style.css';
const useStyles = makeStyles((theme) => ({
  TableHeight: {
    maxHeight: '330px !important',
  },
  customTableContainer: {
    overflowX: 'initial',
  },
  EobContainer: {
    display: 'flex',
    padding: '2rem',
    flexDirection: 'column',
  },
  headingContainer: {
    display: 'flex',
    gap: 20,
  },
  heading: {
    fontWeight: 500,
    fontSize: 17,
  },
  closebtn: {
    display: 'flex',
    flexDirection: 'row-reverse',
  },
}));
function Eob(props) {
  const [EobList, setEobList] = useState([]);
  const [currentFile, setCurrentFile] = useState(null);
  const classes = useStyles();

  useEffect(() => {
    if (props.claimId) {
      props.getClaimList({
        data: props.claimId,
        applicationId: props.applicationId,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.claimId]);

  useEffect(() => {
    if (props.accountsData.claimList && props.accountsData.claimList.response) {
      const response = props.accountsData.claimList.response;
      if (response.responseCode === 0) {
        setEobList(response.data);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.accountsData.claimList]);

  const downloadFiles = (content, fileName, contentType) => {
    if (!contentType) contentType = 'application/octet-stream';
    var a = document.createElement('a');
    var blob = new Blob([content], { type: contentType });
    a.href = URL.createObjectURL(blob);
    a.download = fileName;
    a.click();
    blob = null;
  };

  useEffect(() => {
    if (props.accountsData.claimFile) {
      let blobData = props.accountsData.claimFile.response;
      let fileName = currentFile;
      let fileType = currentFile.split('.')[1];
      downloadFiles(blobData, fileName, fileType);
      props.claimDownloadRes(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.accountsData.claimFile]);

  const handleView = (x) => {
    setCurrentFile(x.fileName);
    props.claimDownload(x);
  };

  return (
    <div>
      <Dialog
        open={props.claimId ? true : false}
        fullWidth
        maxWidth="lg"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className={classes.EobContainer}>
          <div className="closeBtn">
            <Button
              style={{ color: 'white' }}
              variant="contained"
              color="primary"
              onClick={() => {
                setEobList([]);
                props.handleEobClose();
              }}
            >
              Close
            </Button>
            <span style={{ fontWeight: 'bold' }}>EOB</span>
          </div>

          <TableContainer className={classes.TableHeight}>
            <Table
              stickyHeader
              className={classes.table}
              size="small"
              aria-label="a dense table"
            >
              <TableHead className={classes.TableHead}>
                <TableRow>
                  <TableCell className={classes.headCell}>Posted</TableCell>
                  <TableCell className={classes.headCell}>Payer</TableCell>
                  <TableCell className={classes.headCell}>Check Date</TableCell>
                  <TableCell className={classes.headCell}>Amount</TableCell>
                  <TableCell className={classes.headCell}>Check #</TableCell>
                  {/* <TableCell className={classes.headCell}>Ref</TableCell> */}
                  <TableCell className={classes.headCell}>Type</TableCell>
                  {/* <TableCell className={classes.headCell}>UnApplied</TableCell> */}
                  <TableCell className={classes.headCell}>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {EobList.map((x, i) => (
                  <TableRow key={`row${i}`}>
                    <TableCell align={'left'}>{x.postDate}</TableCell>
                    <TableCell align={'left'}>{x.payerName}</TableCell>
                    <TableCell align={'left'}>{x.checkdate}</TableCell>
                    <TableCell align={'left'}>${x.amount}</TableCell>
                    <TableCell align={'left'}>
                      {x.checkNo ? x.checkNo : x.cardNo}
                    </TableCell>
                    <TableCell align={'left'}>{x.payerType}</TableCell>
                    {/* <TableCell align={'left'}>${x.unAppliedAmount}</TableCell> */}
                    <TableCell align={'left'}>
                      <div className="account_table_actions">
                        <Button
                          onClick={() => handleView(x)}
                          variant="outlined"
                          color="primary"
                          type="submit"
                          size="small"
                        >
                          View
                        </Button>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {/* <div className={classes.closebtn}>
            <Button
              className=""
              variant="outlined"
              color="primary"
              onClick={props.handleEobClose}
            >
              Close
            </Button>
          </div> */}
        </div>
      </Dialog>
    </div>
  );
}

const mapStateToProps = (state) => ({
  accountsData: state.coder,
});

const mapDispatchToProps = (dispatch) => ({
  claimDownload: (values) => dispatch(claimDownload(values)),
  getClaimList: (values) => dispatch(getClaimList(values)),
  getCoder: (values) => dispatch(getCoder(values)),
  claimDownloadRes: (values) => dispatch(claimDownloadRes(values)),
});
Eob.propTypes = {
  claimDownload: PropTypes.func,
  getClaimList: PropTypes.func,
  getCoder: PropTypes.func,
  claimDownloadRes: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(Eob);
