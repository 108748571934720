import React, { useState } from 'react';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
export const selectMap = {};

/**
 * @fileOverview Manages Reuseble Drop Down
 * @component
 * @author
 * @param {props} props will contain all the latest values for the autocomplete like value, onChange,Label
 * @return {JSXElement}
 * @example
 * return(
 * <Dropdown/>
 * )
 */

function Dropdown(props) {
  const [value, setValues] = useState(props.value ? props.value : '');
  /**
   * onChange of dropdown
   */

  const handleInput = (event) => {
    setValues(event.target.value);
    if (props.onChange) {
      props.onChange(event);
    }
  };
  return (
    <FormControl
      variant={props.variant}
      size={props.size}
      style={{ width: '100%' }}
      error={props.error}
    >
      <InputLabel required={props.required}>{props.label}</InputLabel>
      <Select value={value} onChange={handleInput} {...props}>
        {props.options &&
          props.options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.name}
            </MenuItem>
          ))}
      </Select>
      <FormHelperText>{props.error ? props.helperText : null}</FormHelperText>
    </FormControl>
  );
}
export default Dropdown;
