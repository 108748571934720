import React, { useEffect } from "react";
import { connect } from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import "./style.css";
import { Grid } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import AssignmentIcon from "@material-ui/icons/Assignment";
import { useState } from "react";
import { dateTommddyyy } from "HelperFunctions/TimeDateTimestamp";
import Skeleton from "@material-ui/lab/Skeleton";
function LogDialog(props) {
  const [log, setLog] = useState([]);
  // const [skeleton, setSkeleton] = useState(true);
  useEffect(() => {
    if (props.coderData.logList) {
      let response = props.coderData.logList.response;
      // console.log(response);
      if (response.responseCode === 0) {
        let lg = [];
        response.data.forEach((x) => {
          lg.push({
            note: x.note,
            date: dateTommddyyy(x.date),
          });
        });
        setLog(lg.reverse());
        // setSkeleton(false);
      }
    }
  }, [props.coderData.logList]);
  return (
    <Dialog
      onClose={() => {
        setLog([]);
        props.handleClose();
      }}
      aria-labelledby="simple-dialog-title"
      open={props.open}
      className="log_dialog"
    >
      <div className="log_content">
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <div className="log_title_container">
              <p className="log_title">Log</p>
              <AssignmentIcon fontSize="small" color="primary" />
            </div>
          </Grid>
          <Grid item>
            <CloseIcon
              onClick={() => {
                setLog([]);
                props.handleClose();
              }}
            />
          </Grid>
        </Grid>

        <div className="main_info">
          <div className="Patient_info">
            <div className="name_info">Patient Name</div>:
            <div className="name_info">{props.name}</div>
          </div>
          {log.map((x, i) => (
            <div key={i} className="log_info">
              <div className="date_div">{x.date}</div>-
              <div className="text_div">{x.note}</div>
            </div>
          ))}
          {log.length === 0 &&
            [1, 2, 3, 4, 5, 3, 3].map((x ,i) => (
              <div key={i} className="log_info_loder">
                <div className="load1">
                  {" "}
                  <Skeleton height={30} />{" "}
                </div>
                <div className="load2">
                  {" "}
                  <Skeleton height={30} />
                </div>
              </div>
            ))}
        </div>
      </div>
    </Dialog>
  );
}

const mapStateToProps = (state) => ({
  coderData: state.coder,
});

const mapDispatchToProps = (dispatch) => ({});
LogDialog.propTypes = {};
export default connect(mapStateToProps, mapDispatchToProps)(LogDialog);
